import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import { addDays } from 'date-fns'
import DateRange from '@material-ui/icons/DateRange'
import moment from 'moment'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Line } from 'react-chartjs-2'

import Loading from '../components/Loading'
import Button from '../components/Button'
import { dashboard } from '../actions/userActions'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { DASHBOARD_RESET } from '../constants/userConstants'

ChartJS.register(...registerables)

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const Dashboard = ({ history }) => {
  // * States
  const [dateModal, setDateModal] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    },
  ])
  const [dates, setDates] = useState([])
  const [datesData, setDatesData] = useState([])
  const [pendingDatesHeadCells, setPendingDatesHeadCells] = useState([])
  const [dataCustomers, setDataCustomers] = useState([])
  const [loading, setLoading] = useState(false)

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.dashboard) {
        history.push('/')
      }
    }
  }, [userInfo, getRoleData, history])

  useEffect(() => {
    let dateObject = state[0]
    let { startDate, endDate } = dateObject

    startDate = moment(startDate).format('YYYY-MM-DD')
    endDate = moment(endDate).format('YYYY-MM-DD')

    setDateModal(false)

    // * dispatch
    setLoading(true)
    dispatch(dashboard(startDate, endDate))
  }, [state])

  const dashboardInfo = useSelector((state) => state.dashboardInfo)
  const { loadingDashboardInfo, dashboardData, errorDashboardInfo } =
    dashboardInfo

  useEffect(() => {
    dispatch({ type: DASHBOARD_RESET })
    if (dashboardData) {
      setLoading(false)
      setDates(dashboardData.dates)
      setDatesData(dashboardData.datesData)
      setPendingDatesHeadCells(dashboardData.pendingDatesHeadCells)
      setDataCustomers(dashboardData.dataCustomers)
    } else if (errorDashboardInfo) {
      setLoading(false)
      toast(errorDashboardInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [dashboardData, errorDashboardInfo])

  const data = {
    labels: dates,
    datasets: [
      {
        label: 'Completed Tasks (Count)',
        data: datesData,
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className='w-full h-full'>
      <div className='flex flex-row justify-between items-center mb-5'>
        <h1 className='text-2xl font-semibold'>Dashboard</h1>
        <Button
          text={<DateRange />}
          custom='py-2 bg-blue-500 hover:bg-blue-600'
          onClick={() => setDateModal(true)}
        />
      </div>
      <div className='bg-white shadow-md rounded px-8 py-8 my-4'>
        <Line
          data={data}
          options={{
            scales: {
              yAxis: {
                min: 0,
                max: 800,
              },
            },
          }}
        />
      </div>
      <MaterialTable
        icons={tableIcons}
        title='Pending Tasks Bank and Date Wise'
        columns={pendingDatesHeadCells}
        data={dataCustomers}
        options={{
          exportButton: false,
          search: true,
          exportAllData: false,
          rowStyle: {
            height: '5px',
            fontSize: 13,
          },
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
          headerStyle: {
            position: 'sticky',
            top: '0',
          },
          minBodyHeight: '450px',
          maxBodyHeight: '450px',
          search: false,
        }}
      />
      {dateModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-full'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Date Range</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <DateRangePicker
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction='horizontal'
                  />
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setDateModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </div>
  )
}

export default Dashboard
