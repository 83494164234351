export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST'
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS'
export const GET_TASKS_FAIL = 'GET_TASKS_FAIL'
export const GET_TASKS_RESET = 'GET_TASKS_RESET'

export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST'
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS'
export const ADD_TASK_FAIL = 'ADD_TASK_FAIL'
export const ADD_TASK_RESET = 'ADD_TASK_RESET'

export const GET_TASK_BY_ID_REQUEST = 'GET_TASK_BY_ID_REQUEST'
export const GET_TASK_BY_ID_SUCCESS = 'GET_TASK_BY_ID_SUCCESS'
export const GET_TASK_BY_ID_FAIL = 'GET_TASK_BY_ID_FAIL'
export const GET_TASK_BY_ID_RESET = 'GET_TASK_BY_ID_RESET'

export const UPDATE_TASK_BY_ID_REQUEST = 'UPDATE_TASK_BY_ID_REQUEST'
export const UPDATE_TASK_BY_ID_SUCCESS = 'UPDATE_TASK_BY_ID_SUCCESS'
export const UPDATE_TASK_BY_ID_FAIL = 'UPDATE_TASK_BY_ID_FAIL'
export const UPDATE_TASK_BY_ID_RESET = 'UPDATE_TASK_BY_ID_RESET'

export const BULK_UPLOAD_TASKS_REQUEST = 'BULK_UPLOAD_TASKS_REQUEST'
export const BULK_UPLOAD_TASKS_SUCCESS = 'BULK_UPLOAD_TASKS_SUCCESS'
export const BULK_UPLOAD_TASKS_FAIL = 'BULK_UPLOAD_TASKS_FAIL'
export const BULK_UPLOAD_TASKS_RESET = 'BULK_UPLOAD_TASKS_RESET'

export const TASK_CHECKED_REQUEST = 'TASK_CHECKED_REQUEST'
export const TASK_CHECKED_SUCCESS = 'TASK_CHECKED_SUCCESS'
export const TASK_CHECKED_FAIL = 'TASK_CHECKED_FAIL'
export const TASK_CHECKED_RESET = 'TASK_CHECKED_RESET'

export const TASK_UPDATE_SUBMIT_REQUEST = 'TASK_UPDATE_SUBMIT_REQUEST'
export const TASK_UPDATE_SUBMIT_SUCCESS = 'TASK_UPDATE_SUBMIT_SUCCESS'
export const TASK_UPDATE_SUBMIT_FAIL = 'TASK_UPDATE_SUBMIT_FAIL'
export const TASK_UPDATE_SUBMIT_RESET = 'TASK_UPDATE_SUBMIT_RESET'

export const TASK_UPDATE_REMARKS_REQUEST = 'TASK_UPDATE_REMARKS_REQUEST'
export const TASK_UPDATE_REMARKS_SUCCESS = 'TASK_UPDATE_REMARKS_SUCCESS'
export const TASK_UPDATE_REMARKS_FAIL = 'TASK_UPDATE_REMARKS_FAIL'
export const TASK_UPDATE_REMARKS_RESET = 'TASK_UPDATE_REMARKS_RESET'

export const TASK_FINALISATION_REQUEST = 'TASK_FINALISATION_REQUEST'
export const TASK_FINALISATION_SUCCESS = 'TASK_FINALISATION_SUCCESS'
export const TASK_FINALISATION_FAIL = 'TASK_FINALISATION_FAIL'
export const TASK_FINALISATION_RESET = 'TASK_FINALISATION_RESET'

export const GET_TRANSFERS_REQUEST = 'GET_TRANSFERS_REQUEST'
export const GET_TRANSFERS_SUCCESS = 'GET_TRANSFERS_SUCCESS'
export const GET_TRANSFERS_FAIL = 'GET_TRANSFERS_FAIL'
export const GET_TRANSFERS_RESET = 'GET_TRANSFERS_RESET'

export const TRANSFER_REQUEST = 'TRANSFER_REQUEST'
export const TRANSFER_SUCCESS = 'TRANSFER_SUCCESS'
export const TRANSFER_FAIL = 'TRANSFER_FAIL'
export const TRANSFER_RESET = 'TRANSFER_RESET'

export const DIAL_REQUEST = 'DIAL_REQUEST'
export const DIAL_SUCCESS = 'DIAL_SUCCESS'
export const DIAL_FAIL = 'DIAL_FAIL'
export const DIAL_RESET = 'DIAL_RESET'

export const TASKS_REPORT_REQUEST = 'TASKS_REPORT_REQUEST'
export const TASKS_REPORT_SUCCESS = 'TASKS_REPORT_SUCCESS'
export const TASKS_REPORT_FAIL = 'TASKS_REPORT_FAIL'
export const TASKS_REPORT_RESET = 'TASKS_REPORT_RESET'

export const SEARCH_TASKS_REQUEST = 'SEARCH_TASKS_REQUEST'
export const SEARCH_TASKS_SUCCESS = 'SEARCH_TASKS_SUCCESS'
export const SEARCH_TASKS_FAIL = 'SEARCH_TASKS_FAIL'
export const SEARCH_TASKS_RESET = 'SEARCH_TASKS_RESET'

export const SEARCH_TRANSFERS_REQUEST = 'SEARCH_TRANSFERS_REQUEST'
export const SEARCH_TRANSFERS_SUCCESS = 'SEARCH_TRANSFERS_SUCCESS'
export const SEARCH_TRANSFERS_FAIL = 'SEARCH_TRANSFERS_FAIL'
export const SEARCH_TRANSFERS_RESET = 'SEARCH_TRANSFERS_RESET'

export const WAIVE_TASK_REQUEST = 'WAIVE_TASK_REQUEST'
export const WAIVE_TASK_SUCCESS = 'WAIVE_TASK_SUCCESS'
export const WAIVE_TASK_FAIL = 'WAIVE_TASK_FAIL'
export const WAIVE_TASK_RESET = 'WAIVE_TASK_RESET'
