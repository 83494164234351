import axios from 'axios'
import {
  ADD_CUSTOMER_TO_USER_FAIL,
  ADD_CUSTOMER_TO_USER_REQUEST,
  ADD_CUSTOMER_TO_USER_SUCCESS,
  ADD_USER_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ATTENDANCE_REPORT_FAIL,
  ATTENDANCE_REPORT_REQUEST,
  ATTENDANCE_REPORT_SUCCESS,
  DASHBOARD_FAIL,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  FETCH_ATTENDANCE_FAIL,
  FETCH_ATTENDANCE_REQUEST,
  FETCH_ATTENDANCE_SUCCESS,
  GET_ROLE_FAIL,
  GET_ROLE_REQUEST,
  GET_ROLE_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_BY_EMAIL_FAIL,
  GET_USER_BY_EMAIL_REQUEST,
  GET_USER_BY_EMAIL_SUCCESS,
  GET_USER_BY_ID_FAIL,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  REJOIN_USER_FAIL,
  REJOIN_USER_REQUEST,
  REJOIN_USER_SUCCESS,
  REMOVE_CUSTOMER_FROM_USER_FAIL,
  REMOVE_CUSTOMER_FROM_USER_REQUEST,
  REMOVE_CUSTOMER_FROM_USER_SUCCESS,
  RESET_DEVICE_ID_FAIL,
  RESET_DEVICE_ID_REQUEST,
  RESET_DEVICE_ID_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESIGN_USERS_FAIL,
  RESIGN_USERS_REQUEST,
  RESIGN_USERS_SUCCESS,
  RESIGN_USER_FAIL,
  RESIGN_USER_REQUEST,
  RESIGN_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  USERS_REPORT_FAIL,
  USERS_REPORT_REQUEST,
  USERS_REPORT_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from '../constants/userConstants'

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      `/users/login`,
      { email, password },
      config
    )

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })

    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const logout = () => async (dispatch) => {
  localStorage.removeItem('userInfo')
  dispatch({
    type: USER_LOGOUT,
  })
}

export const getRole = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ROLE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/roles/${userInfo.role._id}`, config)

    dispatch({
      type: GET_ROLE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_ROLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const dashboard = (startDate, endDate) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DASHBOARD_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/users/dashboard?startDate=${startDate}&endDate=${endDate}`,
      config
    )

    dispatch({
      type: DASHBOARD_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: DASHBOARD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USERS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/users`, config)

    dispatch({
      type: GET_USERS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_USERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserByEmail = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USER_BY_EMAIL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/users/email/${email}`, config)

    dispatch({
      type: GET_USER_BY_EMAIL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_USER_BY_EMAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addUser =
  (name, identifier, email, role, customers, ipAddress) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_USER_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/users`,
        { name, identifier, email, role, customers, ipAddress },
        config
      )

      dispatch({
        type: ADD_USER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: ADD_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getUserByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USER_BY_ID_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/users/${id}`, config)

    dispatch({
      type: GET_USER_BY_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_USER_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUser =
  // (id, name, role, calling, extension, extensionPassword) =>


    (id, name, role, calling, identifier, ipAddress, distanceForCalling) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: UPDATE_USER_REQUEST,
        })

        const {
          userLogin: { userInfo },
        } = getState()

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        const { data } = await axios.put(
          `/users/${id}`,
          // { name, role, calling, extension, extensionPassword },
          { name, role, calling, identifier, ipAddress, distanceForCalling },
          config
        )

        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: data,
        })
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout())
          return
        }
        dispatch({
          type: UPDATE_USER_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
      }
    }

export const resetDeviceID = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESET_DEVICE_ID_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/users/reset-device-id/${id}`, {}, config)

    dispatch({
      type: RESET_DEVICE_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: RESET_DEVICE_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const resetPassword = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/users/reset-password/${id}`, {}, config)

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const removeCustomer = (id, customer) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REMOVE_CUSTOMER_FROM_USER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/users/remove-customer/${id}`,
      { customer },
      config
    )

    dispatch({
      type: REMOVE_CUSTOMER_FROM_USER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: REMOVE_CUSTOMER_FROM_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addCustomer = (id, customer) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_CUSTOMER_TO_USER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/users/add-customer/${id}`,
      { customer },
      config
    )

    dispatch({
      type: ADD_CUSTOMER_TO_USER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: ADD_CUSTOMER_TO_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const fetchAttendance = (id, date) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_ATTENDANCE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/users/fetch-attendance/${id}/${date}`,
      config
    )

    dispatch({
      type: FETCH_ATTENDANCE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: FETCH_ATTENDANCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const resignUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESIGN_USERS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/users/resign`, config)

    dispatch({
      type: RESIGN_USERS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: RESIGN_USERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const resignUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESIGN_USER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/users/resign/${id}`, {}, config)

    dispatch({
      type: RESIGN_USER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: RESIGN_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const rejoinUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REJOIN_USER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/users/rejoin`, { id }, config)

    dispatch({
      type: REJOIN_USER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: REJOIN_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const attendance =
  (startDate, endDate) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ATTENDANCE_REPORT_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/users/reports/attendance?startDate=${startDate}&endDate=${endDate}`,
        config
      )

      dispatch({
        type: ATTENDANCE_REPORT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: ATTENDANCE_REPORT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const users = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USERS_REPORT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/users/reports/users`, config)

    dispatch({
      type: USERS_REPORT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: USERS_REPORT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
