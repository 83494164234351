import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { getRole, logout } from '../actions/userActions'
import Home from '@material-ui/icons/Home'
import Assignment from '@material-ui/icons/Assignment'
import ExitToApp from '@material-ui/icons/ExitToApp'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import GroupIcon from '@material-ui/icons/Group'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import TransformOutlined from '@material-ui/icons/TransformOutlined'
import Assessment from '@material-ui/icons/Assessment'
import Settings from '@material-ui/icons/Settings'
import PersonPinCircle from '@material-ui/icons/PersonPinCircle'
import logo from '../logo.png'
import pjson from '../../package.json'

const Sidebar = ({ route, history }) => {
  // * Initialization
  const dispatch = useDispatch()
  const location = useLocation()

  // * On logout click
  const logoutClick = () => {
    dispatch(logout())
  }

  // * States
  const [name, setName] = useState('')
  const [role, setRole] = useState({})

  // * Get logged in user's info
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name)
      dispatch(getRole())
    }
  }, [userInfo])

  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, errorGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      setRole(getRoleData)
    } else if (errorGetRole) {
      logoutClick()
    }
  }, [getRoleData, errorGetRole])

  return (
    <div className='w-1/5 min-h-screen absolute sm:relative bg-gray-800 shadow flex-col justify-between hidden sm:flex'>
      <div className='px-8'>
        <div className='h-16 w-full flex flex-row items-center gap-2 mt-5'>
          <img src={logo} alt='GoLog' className='w-20' />
          <h3 className='text-2xl font-semibold text-white'>GoLog</h3>
          <p className='text-sm text-white mt-1'>(v{pjson.version})</p>
        </div>
        {!loadingGetRole && (
          <ul className='mt-12'>
            {role.dashboard === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/dashboard'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/dashboard'
              >
                <div className='flex items-center'>
                  <Home />
                  <span className='text-md ml-2'>Dashboard</span>
                </div>
              </Link>
            )}
            {role.tasksView === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/tasks'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/tasks'
              >
                <div className='flex items-center'>
                  <Assignment />
                  <span className='text-md ml-2'>Tasks</span>
                </div>
              </Link>
            )}
            {role.transfers === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/transfers'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/transfers'
              >
                <div className='flex items-center'>
                  <TransformOutlined />
                  <span className='text-md ml-2'>Transfers</span>
                </div>
              </Link>
            )}
            {role.usersView === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/users'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/users'
              >
                <div className='flex items-center'>
                  <GroupIcon />
                  <span className='text-md ml-2'>Users</span>
                </div>
              </Link>
            )}
            {role.resignedUsers === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/resigned'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/resigned'
              >
                <div className='flex items-center'>
                  <GroupIcon />
                  <span className='text-md ml-2'>Resigned Users</span>
                </div>
              </Link>
            )}
            {role.customersView === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/customers'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/customers'
              >
                <div className='flex items-center'>
                  <AssignmentIndIcon />
                  <span className='text-md ml-2'>Customers</span>
                </div>
              </Link>
            )}
            {role.rolesView === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/roles'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/roles'
              >
                <div className='flex items-center'>
                  <MergeTypeIcon />
                  <span className='text-md ml-2'>Roles</span>
                </div>
              </Link>
            )}
            {role.reports === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname.includes('reports')
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/reports'
              >
                <div className='flex items-center'>
                  <Assessment />
                  <span className='text-md ml-2'>Reports</span>
                </div>
              </Link>
            )}
            {role.settings === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/settings'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/settings'
              >
                <div className='flex items-center'>
                  <Settings />
                  <span className='text-md ml-2'>Settings</span>
                </div>
              </Link>
            )}
            {role.pincodes === true && (
              <Link
                className={`flex w-full justify-between ${
                  location.pathname === '/pincodes'
                    ? 'text-gray-300'
                    : 'text-gray-600'
                } hover:text-gray-500 cursor-pointer items-center mb-6`}
                to='/pincodes'
              >
                <div className='flex items-center'>
                  <PersonPinCircle />
                  <span className='text-md ml-2'>Pincodes</span>
                </div>
              </Link>
            )}
          </ul>
        )}
      </div>
      <div className='px-8 border-t border-gray-700'>
        <ul className='w-full flex items-center justify-between bg-gray-800'>
          <li
            className='flex items-center w-full cursor-pointer text-white pt-5 pb-3'
            onClick={logoutClick}
          >
            <ExitToApp />
            <div className='flex flex-col'>
              <span className='text-sm ml-6'>{name}</span>
              <span className='text-sm ml-6'>Role: {role.name}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
