import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'

import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Select from '../components/Select'
import { getTransfers, searchTransfers, transfer } from '../actions/taskActions'
import {
  GET_TRANSFERS_RESET,
  SEARCH_TRANSFERS_RESET,
  TRANSFER_RESET,
} from '../constants/taskConstants'
import Input from '../components/Input'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const Transfers = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [id, setId] = useState('')
  const [assignedTo, setAssignedTo] = useState('')
  const [users, setUsers] = useState([])
  const [usersOptions, setUsersOptions] = useState([])
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('ASC')

  // * Table sorting by Task ID
  const sortingByTaskID = (col) => {
    if (order === 'ASC') {
      const sorted = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1))
      setData(sorted)
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const sorted = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1))
      setData(sorted)
      setOrder('ASC')
    }
  }

  // * Table sorting
  const sorting = (col) => {
    if (order === 'ASC') {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setData(sorted)
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setData(sorted)
      setOrder('ASC')
    }
  }

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.transfers) {
        history.push('/')
      }
    }
  }, [userInfo, getRoleData, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getTransfers(page))
  }, [])

  // * Users Table
  const headCells = [
    {
      field: 'taskID',
      title: 'Task ID',
      render: (rowData) => (
        <div
          className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
          onClick={() => updateTask(rowData)}
        >
          <p>{rowData.taskID}</p>
        </div>
      ),
    },
    {
      field: 'assignedToName',
      title: 'Allocated On Field To',
    },
    {
      field: 'visitFinishedOn',
      title: 'Transferred On',
    },
    {
      field: 'customerName',
      title: 'Customer',
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'address',
      title: 'Address',
    },
    {
      field: 'mobile',
      title: 'Mobile',
    },
    {
      field: 'loanNumber',
      title: 'Loan Number',
    },
  ]

  const getTransfersInfo = useSelector((state) => state.getTransfersInfo)
  const { loadingGetTranfers, errorGetTransfers, getTransfersData } =
    getTransfersInfo

  useEffect(() => {
    dispatch({ type: GET_TRANSFERS_RESET })
    if (getTransfersData) {
      setLoading(false)
      setData(getTransfersData.data)
      setUsers(getTransfersData.users)
      setPage(getTransfersData.page)
      setPages(getTransfersData.pages)
      setTotal(getTransfersData.count)
    } else if (errorGetTransfers) {
      setLoading(false)
      toast(errorGetTransfers, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getTransfersData, errorGetTransfers])

  // * Open update task modal
  const updateTask = (item) => {
    setOpenModal(true)
    setId(item._id)
  }

  const closeTransferTaskModal = () => {
    setOpenModal(false)
    setId('')
    setAssignedTo('')
  }

  // * Users
  useEffect(() => {
    if (users.length > 0) {
      let data = []
      users.forEach((item) => {
        data.push({
          id: item._id,
          title: `${item.identifier} - ${item.name}`,
        })
      })
      setUsersOptions(data)
    }
  }, [users])

  const submitTask = () => {
    if (!assignedTo) {
      toast('Select a user from the dropdown', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(transfer(id, assignedTo))
    }
  }

  const transferInfo = useSelector((state) => state.transferInfo)
  const { loadingTranfer, errorTransfer, transferData } = transferInfo

  useEffect(() => {
    dispatch({ type: TRANSFER_RESET })
    if (transferData) {
      toast(transferData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      closeTransferTaskModal()
      setTimeout(() => {
        dispatch(getTransfers())
      }, 1000)
    } else if (errorTransfer) {
      toast(errorTransfer, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [transferData, errorTransfer])

  useEffect(() => {
    if (search !== '') {
      if (search.includes('/')) {
        toast('Please remove / from search field', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        })
        return
      } else {
        setTimeout(() => {
          dispatch(searchTransfers(search))
        }, 100)
      }
    } else {
      dispatch(getTransfers(1))
    }
  }, [search])

  const searchTransfersInfo = useSelector((state) => state.searchTransfersInfo)
  const { loadingSearchTransfers, searchTransfersData, errorSearchTransfers } =
    searchTransfersInfo

  useEffect(() => {
    dispatch({ type: SEARCH_TRANSFERS_RESET })
    if (searchTransfersData) {
      setData(searchTransfersData)
    } else if (errorSearchTransfers) {
      toast(errorSearchTransfers, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [searchTransfersData, errorSearchTransfers])

  if (loading) {
    return <Loading />
  }

  return (
    <div className='w-full h-full'>
      <h1 className='text-2xl font-semibold mb-4'>Transfers</h1>
      <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
        <Input
          width='w-full'
          name='Search by Task ID, Allocation Remark, Loan Number, Mobile Number or Name'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div
        className='w-full bg-white'
        style={{ overflowX: 'auto', height: '85%' }}
      >
        <table
          className='w-full'
          style={{ height: '100%', position: 'relative' }}
        >
          <thead
            className='w-full bg-white'
            style={{ position: 'sticky', insetBlockStart: 0 }}
          >
            <tr className='w-full flex flex-row p-4 gap-4 items-center border border-b-1'>
              {headCells.map((item) => (
                <>
                  {item.field === 'taskID' ? (
                    <th
                      className='w-24 flex items-center'
                      key={item.field}
                      style={{ cursor: 'pointer' }}
                      onClick={() => sortingByTaskID(item.field)}
                    >
                      <p className='text-sm font-semibold'>{item.title}</p>
                    </th>
                  ) : (
                    <th
                      className='w-36 flex items-center'
                      key={item.field}
                      style={{ cursor: 'pointer' }}
                      onClick={() => sorting(item.field)}
                    >
                      <p className='text-sm font-semibold'>{item.title}</p>
                    </th>
                  )}
                </>
              ))}
            </tr>
          </thead>
          <tbody style={{ overflowY: 'auto', height: '60%' }}>
            {data.map((item, index) => (
              <tr
                key={item.index}
                className='w-full flex flex-row p-4 gap-4 items-center border border-b-1'
              >
                {headCells.map((head) => {
                  return (
                    <>
                      {head.field === 'taskID' ? (
                        <td className='w-24 flex items-center'>
                          <div
                            className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
                            onClick={() => updateTask(item)}
                          >
                            <p>{item[head.field]}</p>
                          </div>
                        </td>
                      ) : (
                        <td className='w-36 flex items-center'>
                          <p className='text-sm'>{item[head.field]}</p>
                        </td>
                      )}
                    </>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <div
          className='w-full bg-white border border-t-1 flex flex-row justify-between p-4'
          style={{ position: 'sticky', insetBlockEnd: 0 }}
        >
          <p className='text-sm'>
            Showing{' '}
            {page === 1
              ? '0 - ' + data.length * page
              : data.length * (page - 1) + 1 + ' - ' + data.length * page}
            , Out of {total}
          </p>
          <div className='flex flex-row items-center gap-2'>
            <button
              className='border border-1 px-1 border-black text-sm'
              style={{ cursor: 'pointer' }}
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              &#171;
            </button>
            <button
              className='border border-1 px-1 border-black text-sm'
              style={{ cursor: 'pointer' }}
              onClick={() => setPage(page + 1)}
              disabled={page === pages}
            >
              &#187;
            </button>
          </div>
        </div>
      </div>
      {/* <MaterialTable
        icons={tableIcons}
        title={''}
        columns={headCells}
        data={data}
        options={{
          exportButton: false,
          search: true,
          exportAllData: false,
          rowStyle: {
            height: '5px',
            fontSize: 13,
          },
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
          headerStyle: {
            position: 'sticky',
            top: '0',
          },
        }}
      /> */}
      {openModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Transfer Task</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <Select
                    width='w-full mb-4'
                    name='User *'
                    value={assignedTo}
                    onChange={(e) => setAssignedTo(e.target.value)}
                    options={usersOptions}
                  />
                  <p className='text-sm'>All the fields with * are mandatory</p>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closeTransferTaskModal}
                  >
                    Close
                  </button>

                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                    type='button'
                    onClick={submitTask}
                    disabled={loadingTranfer}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </div>
  )
}

export default Transfers
