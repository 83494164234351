import {
  ADD_TASK_FAIL,
  ADD_TASK_REQUEST,
  ADD_TASK_RESET,
  ADD_TASK_SUCCESS,
  BULK_UPLOAD_TASKS_FAIL,
  BULK_UPLOAD_TASKS_REQUEST,
  BULK_UPLOAD_TASKS_RESET,
  BULK_UPLOAD_TASKS_SUCCESS,
  DIAL_FAIL,
  DIAL_REQUEST,
  DIAL_RESET,
  DIAL_SUCCESS,
  GET_TASKS_FAIL,
  GET_TASKS_REQUEST,
  GET_TASKS_RESET,
  GET_TASKS_SUCCESS,
  GET_TASK_BY_ID_FAIL,
  GET_TASK_BY_ID_REQUEST,
  GET_TASK_BY_ID_RESET,
  GET_TASK_BY_ID_SUCCESS,
  GET_TRANSFERS_FAIL,
  GET_TRANSFERS_REQUEST,
  GET_TRANSFERS_RESET,
  GET_TRANSFERS_SUCCESS,
  SEARCH_TASKS_FAIL,
  SEARCH_TASKS_REQUEST,
  SEARCH_TASKS_RESET,
  SEARCH_TASKS_SUCCESS,
  SEARCH_TRANSFERS_FAIL,
  SEARCH_TRANSFERS_REQUEST,
  SEARCH_TRANSFERS_RESET,
  SEARCH_TRANSFERS_SUCCESS,
  TASKS_REPORT_FAIL,
  TASKS_REPORT_REQUEST,
  TASKS_REPORT_RESET,
  TASKS_REPORT_SUCCESS,
  TASK_CHECKED_FAIL,
  TASK_CHECKED_REQUEST,
  TASK_CHECKED_RESET,
  TASK_CHECKED_SUCCESS,
  TASK_FINALISATION_FAIL,
  TASK_FINALISATION_REQUEST,
  TASK_FINALISATION_RESET,
  TASK_FINALISATION_SUCCESS,
  TASK_UPDATE_REMARKS_FAIL,
  TASK_UPDATE_REMARKS_REQUEST,
  TASK_UPDATE_REMARKS_RESET,
  TASK_UPDATE_REMARKS_SUCCESS,
  TASK_UPDATE_SUBMIT_FAIL,
  TASK_UPDATE_SUBMIT_REQUEST,
  TASK_UPDATE_SUBMIT_RESET,
  TASK_UPDATE_SUBMIT_SUCCESS,
  TRANSFER_FAIL,
  TRANSFER_REQUEST,
  TRANSFER_RESET,
  TRANSFER_SUCCESS,
  UPDATE_TASK_BY_ID_FAIL,
  UPDATE_TASK_BY_ID_REQUEST,
  UPDATE_TASK_BY_ID_RESET,
  UPDATE_TASK_BY_ID_SUCCESS,
  WAIVE_TASK_FAIL,
  WAIVE_TASK_REQUEST,
  WAIVE_TASK_RESET,
  WAIVE_TASK_SUCCESS,
} from '../constants/taskConstants'

export const getTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TASKS_REQUEST:
      return { loadingGetTasksInfo: true }
    case GET_TASKS_SUCCESS:
      return { loadingGetTasksInfo: false, getTasksData: action.payload }
    case GET_TASKS_FAIL:
      return { loadingGetTasksInfo: false, errorGetTasksInfo: action.payload }
    case GET_TASKS_RESET:
      return {}
    default:
      return state
  }
}

export const addTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_TASK_REQUEST:
      return { loadingAddTaskInfo: true }
    case ADD_TASK_SUCCESS:
      return { loadingAddTaskInfo: false, addTaskData: action.payload }
    case ADD_TASK_FAIL:
      return { loadingAddTaskInfo: false, errorAddTaskInfo: action.payload }
    case ADD_TASK_RESET:
      return {}
    default:
      return state
  }
}

export const getTaskByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TASK_BY_ID_REQUEST:
      return { loadingGetTaskByIDInfo: true }
    case GET_TASK_BY_ID_SUCCESS:
      return {
        loadingGetTaskByIDInfo: false,
        getTaskByIDData: action.payload,
      }
    case GET_TASK_BY_ID_FAIL:
      return {
        loadingGetTaskByIDInfo: false,
        errorGetTaskByIDInfo: action.payload,
      }
    case GET_TASK_BY_ID_RESET:
      return {}
    default:
      return state
  }
}

export const updateTaskByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TASK_BY_ID_REQUEST:
      return { loadingUpdateTaskByIDInfo: true }
    case UPDATE_TASK_BY_ID_SUCCESS:
      return {
        loadingUpdateTaskByIDInfo: false,
        updateTaskByIDData: action.payload,
      }
    case UPDATE_TASK_BY_ID_FAIL:
      return {
        loadingUpdateTaskByIDInfo: false,
        errorUpdateTaskByIDInfo: action.payload,
      }
    case UPDATE_TASK_BY_ID_RESET:
      return {}
    default:
      return state
  }
}

export const bulkUploadTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case BULK_UPLOAD_TASKS_REQUEST:
      return { loadingBulkUploadTasksInfo: true }
    case BULK_UPLOAD_TASKS_SUCCESS:
      return {
        loadingBulkUploadTasksInfo: false,
        addBulkUploadTasksData: action.payload,
      }
    case BULK_UPLOAD_TASKS_FAIL:
      return {
        loadingBulkUploadTasksInfo: false,
        errorBulkUploadTasksInfo: action.payload,
      }
    case BULK_UPLOAD_TASKS_RESET:
      return {}
    default:
      return state
  }
}

export const taskCheckedReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_CHECKED_REQUEST:
      return { loadingTaskCheckedInfo: true }
    case TASK_CHECKED_SUCCESS:
      return {
        loadingTaskCheckedInfo: false,
        taskCheckedData: action.payload,
      }
    case TASK_CHECKED_FAIL:
      return {
        loadingTaskCheckedInfo: false,
        errorTaskCheckedInfo: action.payload,
      }
    case TASK_CHECKED_RESET:
      return {}
    default:
      return state
  }
}

export const taskUpdateSubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_UPDATE_SUBMIT_REQUEST:
      return { loadingUpdateSubmitTask: true }
    case TASK_UPDATE_SUBMIT_SUCCESS:
      return {
        loadingUpdateSubmitTask: false,
        updateSubmitTaskData: action.payload,
      }
    case TASK_UPDATE_SUBMIT_FAIL:
      return {
        loadingUpdateSubmitTask: false,
        errorUpdateSubmitTask: action.payload,
      }
    case TASK_UPDATE_SUBMIT_RESET:
      return {}
    default:
      return state
  }
}

export const taskUpdateRemarksReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_UPDATE_REMARKS_REQUEST:
      return { loadingUpdateRemarksTask: true }
    case TASK_UPDATE_REMARKS_SUCCESS:
      return {
        loadingUpdateRemarksTask: false,
        updateRemarksTaskData: action.payload,
      }
    case TASK_UPDATE_REMARKS_FAIL:
      return {
        loadingUpdateRemarksTask: false,
        errorUpdateRemarksTask: action.payload,
      }
    case TASK_UPDATE_REMARKS_RESET:
      return {}
    default:
      return state
  }
}

export const taskFinalisationReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_FINALISATION_REQUEST:
      return { loadingTaskFinalisation: true }
    case TASK_FINALISATION_SUCCESS:
      return {
        loadingTaskFinalisation: false,
        taskFinalisationData: action.payload,
      }
    case TASK_FINALISATION_FAIL:
      return {
        loadingTaskFinalisation: false,
        errorTaskFinalisation: action.payload,
      }
    case TASK_FINALISATION_RESET:
      return {}
    default:
      return state
  }
}
export const getTransfersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TRANSFERS_REQUEST:
      return { loadingGetTransfers: true }
    case GET_TRANSFERS_SUCCESS:
      return {
        loadingGetTransfers: false,
        getTransfersData: action.payload,
      }
    case GET_TRANSFERS_FAIL:
      return {
        loadingGetTransfers: false,
        errorGetTransfers: action.payload,
      }
    case GET_TRANSFERS_RESET:
      return {}
    default:
      return state
  }
}

export const transferReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSFER_REQUEST:
      return { loadingTransfer: true }
    case TRANSFER_SUCCESS:
      return {
        loadingTransfer: false,
        transferData: action.payload,
      }
    case TRANSFER_FAIL:
      return {
        loadingTransfer: false,
        errorTransfer: action.payload,
      }
    case TRANSFER_RESET:
      return {}
    default:
      return state
  }
}

export const dialReducer = (state = {}, action) => {
  switch (action.type) {
    case DIAL_REQUEST:
      return { loadingDial: true }
    case DIAL_SUCCESS:
      return {
        loadingDial: false,
        dialData: action.payload,
      }
    case DIAL_FAIL:
      return {
        loadingDial: false,
        errorDial: action.payload,
      }
    case DIAL_RESET:
      return {}
    default:
      return state
  }
}

export const tasksReportReducer = (state = {}, action) => {
  switch (action.type) {
    case TASKS_REPORT_REQUEST:
      return { loadingTasksReport: true }
    case TASKS_REPORT_SUCCESS:
      return {
        loadingTasksReport: false,
        tasksReportData: action.payload,
      }
    case TASKS_REPORT_FAIL:
      return {
        loadingTasksReport: false,
        errorTasksReport: action.payload,
      }
    case TASKS_REPORT_RESET:
      return {}
    default:
      return state
  }
}

export const searchTasksReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_TASKS_REQUEST:
      return { loadingSearchTasks: true }
    case SEARCH_TASKS_SUCCESS:
      return {
        loadingSearchTasks: false,
        searchTasksData: action.payload,
      }
    case SEARCH_TASKS_FAIL:
      return {
        loadingSearchTasks: false,
        errorSearchTasks: action.payload,
      }
    case SEARCH_TASKS_RESET:
      return {}
    default:
      return state
  }
}

export const searchTransfersReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_TRANSFERS_REQUEST:
      return { loadingSearchTransfers: true }
    case SEARCH_TRANSFERS_SUCCESS:
      return {
        loadingSearchTransfers: false,
        searchTransfersData: action.payload,
      }
    case SEARCH_TRANSFERS_FAIL:
      return {
        loadingSearchTransfers: false,
        errorSearchTransfers: action.payload,
      }
    case SEARCH_TRANSFERS_RESET:
      return {}
    default:
      return state
  }
}

export const waiveTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case WAIVE_TASK_REQUEST:
      return { loadingWaiveTask: true }
    case WAIVE_TASK_SUCCESS:
      return {
        loadingWaiveTask: false,
        waiveTaskData: action.payload,
      }
    case WAIVE_TASK_FAIL:
      return {
        loadingWaiveTask: false,
        errorWaiveTask: action.payload,
      }
    case WAIVE_TASK_RESET:
      return {}
    default:
      return state
  }
}
