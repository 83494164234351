import axios from 'axios'

import {
  ADD_PINCODE_FAIL,
  ADD_PINCODE_REQUEST,
  ADD_PINCODE_SUCCESS,
  GET_PINCODES_FAIL,
  GET_PINCODES_REQUEST,
  GET_PINCODES_SUCCESS,
  UPDATE_PINCODE_FAIL,
  UPDATE_PINCODE_REQUEST,
  UPDATE_PINCODE_SUCCESS,
} from '../constants/pincodeConstants'
import { logout } from './userActions'

export const getPincodes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PINCODES_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/pincodes`, config)

    dispatch({
      type: GET_PINCODES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_PINCODES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addPincode = (pincode, user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_PINCODE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/pincodes`, { pincode, user }, config)

    dispatch({
      type: ADD_PINCODE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: ADD_PINCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updatePincode = (id, user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_PINCODE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/pincodes/${id}`, { user }, config)

    dispatch({
      type: UPDATE_PINCODE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: UPDATE_PINCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
