import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import { addDays } from 'date-fns'
import DateRange from '@material-ui/icons/DateRange'
import { toast } from 'react-toastify'
import moment from 'moment'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

import Button from '../components/Button'
import { tasks } from '../actions/taskActions'
import Loading from '../components/Loading'
import { TASKS_REPORT_RESET } from '../constants/taskConstants'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const TaskReport = ({ history }) => {
  const dispatch = useDispatch()

  const [dateModal, setDateModal] = useState(false)
  const [data, setData] = useState([])

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    },
  ])

  useEffect(() => {
    let dateObject = state[0]
    let { startDate, endDate } = dateObject

    startDate = moment(startDate).format('YYYY-MM-DD')
    endDate = moment(endDate).format('YYYY-MM-DD')

    setDateModal(false)

    dispatch(tasks(startDate, endDate))
  }, [state])

  const tasksReportInfo = useSelector((state) => state.tasksReportInfo)
  const { loadingTasksReport, errorTasksReport, tasksReportData } =
    tasksReportInfo

  useEffect(() => {
    dispatch({ type: TASKS_REPORT_RESET })
    if (tasksReportData) {
      setData(tasksReportData)
    } else if (errorTasksReport) {
      toast(errorTasksReport, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [errorTasksReport, tasksReportData])

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.reports) {
        history.push('/')
      }
    }
  }, [userInfo, getRoleData, history])

  const headCells = [
    {
      field: 'taskID',
      title: 'Task ID',
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'mobile',
      title: 'Mobile',
    },
    {
      field: 'address',
      title: 'Address',
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'branch',
      title: 'Branch',
    },
    {
      field: 'loanType',
      title: 'Loan Type',
    },
    {
      field: 'loanNumber',
      title: 'Loan Number',
    },
    {
      field: 'financeAmount',
      title: 'Finance Amount',
    },
    {
      field: 'receiptDate',
      title: 'Receipt Date',
    },
    {
      field: 'information.allocationRemarks',
      title: 'Allocation Remarks',
    },
    {
      field: 'customer.name',
      title: 'Customer',
    },
    {
      // field: 'customerUserName',
      field: 'customerUser.name',
      title: 'Customer User',
    },
    {
      field: 'allocatedByBankOn',
      title: 'Allocated By Bank On',
    },
    {
      // field: 'assignedByName',
      field: 'assignedBy.name',
      title: 'Assigned By',
    },
    {
      field: 'allocatedOnFieldOn',
      title: 'Allocated On Field On',
    },
    {
      // field: 'assignedToName',
      field: 'assignedTo.name',
      title: 'Assigned To',
    },
    {
      field: 'visitStartedOn',
      title: 'Visit Started On',
    },
    {
      field: 'visitFinishedOn',
      title: 'Visit Finished On',
    },
    {
      // field: 'checkedByName',
      field: 'checkedBy.name',
      title: 'Checked By',
    },
    {
      field: 'calls.length',
      title: 'Calls Done',
    },
    {
      field: 'documents.length',
      title: 'Documents Clicked',
    },
    {
      field: 'photographs.length',
      title: 'Photographs Clicked',
    },
    {
      field: 'status',
      title: 'Task Status',
    },
    {
      field: 'information.addressFound',
      title: 'Address Found',
    },
    {
      field: 'information.status',
      title: 'Status',
    },
    {
      field: 'information.reason',
      title: 'Reason',
    },
    {
      field: 'information.personMet',
      title: 'Person Met',
    },
    {
      field: 'information.relationshipWithApplicant',
      title: 'Relationship with Applicant',
    },
    {
      field: 'information.relationshipWithApplicantDetails',
      title: 'Relationship with Applicant Details',
    },
    {
      field: 'information.designationOfPersonMet',
      title: 'Designation of Applicant',
    },
    {
      field: 'information.applicantAge',
      title: 'Applicant Age',
    },
    {
      field: 'information.addressChange',
      title: 'Address Change',
    },
    {
      field: 'information.educationQualification',
      title: 'Education Qualification',
    },
    {
      field: 'information.idSeen',
      title: 'ID Seen',
    },
    {
      field: 'information.idProofType',
      title: 'ID Proof Type',
    },
    {
      field: 'information.addressProofSeen',
      title: 'Address Proof Seen',
    },
    {
      field: 'information.addressProofType',
      title: 'Address Proof Type',
    },
    {
      field: 'information.maritalStatus',
      title: 'Marital Status',
    },
    {
      field: 'information.numberOfFamilyMembers',
      title: 'Number of Family Members',
    },
    {
      field: 'information.numberOfDependent',
      title: 'Number of Dependents',
    },
    {
      field: 'information.typeOfFamily',
      title: 'Type of Family',
    },
    {
      field: 'information.typeOfFamilyDetails',
      title: 'Type of Family Details',
    },
    {
      field: 'information.occupation',
      title: 'Occupation',
    },
    {
      field: 'information.occupationDetails',
      title: 'Occupation Details',
    },
    {
      field: 'information.occupationOthers',
      title: 'Occupation Others',
    },
    {
      field: 'information.applicantDesignation',
      title: 'Applicant Designation',
    },
    {
      field: 'information.applicantDepartment',
      title: 'Applicant Department',
    },
    {
      field: 'information.typeOfOrganisation',
      title: 'Type of Organisation',
    },
    {
      field: 'information.entryRestricted',
      title: 'Entry Restricted',
    },
    {
      field: 'information.businessPremisesOwnedBy',
      title: 'Business Premises Owned By',
    },
    {
      field: 'information.businessPremisesOwnedBy',
      title: 'Business Premises Owned By',
    },
    {
      field: 'information.residenceOwnedBy',
      title: 'Residence Owned By',
    },
    {
      field: 'information.residenceOwnedByDetails',
      title: 'Residence Owned By Details',
    },
    {
      field: 'information.rentAmount',
      title: 'Rent Amount (If Rented)',
    },
    {
      field: 'information.landlordName',
      title: 'Landlord Name (If Rented)',
    },
    {
      field: 'information.workingSince',
      title: 'Working Since',
    },
    {
      field: 'information.tenureOfStay',
      title: 'Tenure of Stay',
    },
    {
      field: 'information.namePlateSeen',
      title: 'Name Plate Seen',
    },
    {
      field: 'information.nameMentionedOnNamePlate',
      title: 'Name Mentioned on Name Plate',
    },
    {
      field: 'information.nameBoardSeen',
      title: 'Name Board Seen',
    },
    {
      field: 'information.nameMentionedOnNameBoard',
      title: 'Name Mentioned on Board Seen',
    },
    {
      field: 'information.area',
      title: 'Area (sq. ft.)',
    },
    {
      field: 'information.floorVisited',
      title: 'Floor Visited',
    },
    {
      field: 'information.typeOfHouse',
      title: 'Type of House',
    },
    {
      field: 'information.furnishingOfTheHouse',
      title: 'Furnishing of the House',
    },
    {
      field: 'information.totalEmployees',
      title: 'Total Employees',
    },
    {
      field: 'information.employeesSeen',
      title: 'Employees Seen',
    },
    {
      field: 'information.employeesSeen',
      title: 'Employees Seen',
    },
    {
      field: 'information.natureOfBusiness',
      title: 'Nature of Business',
    },
    {
      field: 'information.natureOfBusinessDetails',
      title: 'Nature of Business Details',
    },
    {
      field: 'information.typeOfOffice',
      title: 'Type of Office',
    },
    {
      field: 'information.localityStatus',
      title: 'Locality Status',
    },
    {
      field: 'information.localityType',
      title: 'Locality Type',
    },
    {
      field: 'information.levelOfActivity',
      title: 'Level of Activity',
    },
    {
      field: 'information.stockSeen',
      title: 'Stock Seen',
    },
    {
      field: 'information.furnishingOfOffice',
      title: 'Furnishing of Office',
    },
    {
      field: 'information.locatingTheAddress',
      title: 'Locating the Address',
    },
    {
      field: 'information.landmark',
      title: 'Landmark',
    },
    {
      field: 'information.areaType',
      title: 'Area Type',
    },
    {
      field: 'information.areaTypeDetails',
      title: 'Area Type Details',
    },
    {
      field: 'information.residenceAccessedBy',
      title: 'Residence Accessed By',
    },
    {
      field: 'information.residenceAccessedBy',
      title: 'Residence Accessed By',
    },
    {
      field: 'information.previousVisitDone',
      title: 'Previous Visit Done',
    },
    {
      field: 'information.previousVisitCount',
      title: 'Previous Visit Count',
    },
    {
      field: 'information.previousVisitMetPerson',
      title: 'Previous Visit Met Person',
    },
    {
      field: 'information.colorOfBuilding',
      title: 'Color of Building',
    },
    {
      field: 'information.colorOfGate',
      title: 'Color of Gate',
    },
    {
      field: 'information.firstNeighbourName',
      title: 'First Neighbour Name',
    },
    {
      field: 'information.firstNeighbourAge',
      title: 'First Neighbour Age',
    },
    {
      field: 'information.firstNeighbourAddress',
      title: 'First Neighbour Address',
    },
    {
      field: 'information.firstNeighbourFeedback',
      title: 'First Neighbour Feedback',
    },
    {
      field: 'information.secondNeighbourName',
      title: 'Second Neighbour Name',
    },
    {
      field: 'information.secondNeighbourAge',
      title: 'Second Neighbour Age',
    },
    {
      field: 'information.secondNeighbourAddress',
      title: 'Second Neighbour Address',
    },
    {
      field: 'information.secondNeighbourFeedback',
      title: 'Second Neighbour Feedback',
    },
    {
      field: 'information.routeMap',
      title: 'Route Map',
    },
    {
      field: 'information.notes',
      title: 'Notes',
    },
    {
      field: 'information.remarks',
      title: 'Remarks',
    },
    {
      field: 'visitLocation.address',
      title: 'Visit Address',
    },
    {
      field: 'visitLocation.latitude',
      title: 'Visit Location Latitude',
    },
    {
      field: 'visitLocation.longitude',
      title: 'Visit Location Longitude',
    },
    {
      // field: 'finalisationByName',
      field: 'finalisationBy.name',
      title: 'Finalisation By',
    },
    {
      field: 'finalisationStatus',
      title: 'Finalisation Status',
    },
    {
      field: 'finalisationRemarks',
      title: 'Finalisation Remarks',
    },
    {
      field: 'finalisationOn',
      title: 'Finalisation On',
    },
    {
      field: 'visitTat',
      title: 'Visit TAT',
    },
    {
      field: 'finalisationTat',
      title: 'Finalisation TAT',
    },
  ]

  if (loadingTasksReport) {
    return <Loading />
  }

  return (
    <div className='w-full h-full'>
      <div className='flex flex-row justify-between items-center mb-5'>
        <h1 className='text-2xl font-semibold'>Tasks Report</h1>
        <Button
          text={<DateRange />}
          custom='py-2 bg-blue-500 hover:bg-blue-600'
          onClick={() => setDateModal(true)}
        />
      </div>
      <MaterialTable
        icons={tableIcons}
        title={''}
        columns={headCells}
        data={data}
        options={{
          exportButton: true,
          search: true,
          exportAllData: true,
          rowStyle: {
            height: '5px',
            fontSize: 13,
          },
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
          headerStyle: {
            position: 'sticky',
            top: '0',
          },
        }}
      />
      {dateModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-full'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Date Range</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <DateRangePicker
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction='horizontal'
                  />
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setDateModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </div>
  )
}

export default TaskReport
