import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Reports = ({ history }) => {
  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.reports) {
        history.push('/')
      }
    }
  }, [userInfo, getRoleData, history])

  return (
    <div className='w-full h-full'>
      <h1 className='text-2xl font-semibold'>Reports</h1>
      <div className='bg-white shadow-md rounded px-8 py-8 my-4'>
        <table className='w-full'>
          <thead>
            <tr className='w-full flex flex-row justify-between bg-gray-200 py-2 px-5'>
              <td className='text-sm font-semibold flex-1'>NAME</td>
              <td className='text-sm font-semibold flex-1'>DESCRIPTION</td>
            </tr>
          </thead>
          <tbody>
            <tr className='w-full flex flex-row justify-between py-2 px-5 border-b-2 border-x-2 border-gray-100 items-center'>
              <td className='text-sm text-blue-700 flex-1'>
                <Link to={`/reports/attendance`}>Attendance</Link>
              </td>
              <td className='text-sm flex-1'>
                Employee attendance report. This report will show the detailed
                attendance report per employee.
              </td>
            </tr>
            <tr className='w-full flex flex-row justify-between py-2 px-5 border-b-2 border-x-2 border-gray-100 items-center'>
              <td className='text-sm text-blue-700 flex-1'>
                <Link to={`/reports/task`}>Task</Link>
              </td>
              <td className='text-sm flex-1'>
                Detailed tasks report with all the details.
              </td>
            </tr>
            <tr className='w-full flex flex-row justify-between py-2 px-5 border-b-2 border-x-2 border-gray-100 items-center'>
              <td className='text-sm text-blue-700 flex-1'>
                <Link to={`/reports/users`}>Users</Link>
              </td>
              <td className='text-sm flex-1'>
                Detailed users report with all the details.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Reports
