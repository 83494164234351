import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'

import Input from '../components/Input'
import Button from '../components/Button'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Select from '../components/Select'
import { addUser, getUserByEmail, getUsers } from '../actions/userActions'
import {
  ADD_USER_RESET,
  GET_USERS_RESET,
  GET_USER_BY_EMAIL_RESET,
} from '../constants/userConstants'
import { Link } from 'react-router-dom'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const UserList = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [rolesFromServer, setRolesFromServer] = useState([])
  const [roleOptions, setRoleOptions] = useState([])
  const [customersFromServer, setCustomersFromServer] = useState([])
  const [customerOptions, setCustomerOptions] = useState([])
  // * Search
  const [search, setSearch] = useState('')
  // * Add a User
  const [openModal, setOpenModal] = useState(false)
  const [email, setEmail] = useState('')
  const [identifier, setIdentifier] = useState('')
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [customers, setCustomers] = useState([])
  const [newPassword, setNewPassword] = useState('')
  const [ipAddress, setIpAddress] = useState('')
  const [passwordModal, setPasswordModal] = useState(false)

  // * Roles
  const [addUserOption, setAddUserOption] = useState(false)
  const [loading, setLoading] = useState(true)

  // * Remove items from array
  const removeItemsFromArray = (id) => {
    let newArray = customers.filter((item) => item !== id)
    setCustomers(newArray)
  }

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      setAddUserOption(getRoleData.usersAdd)
      if (!getRoleData.usersView) {
        history.push('/')
      }
    }
  }, [userInfo, getRoleData, history])

  useEffect(() => {
    dispatch(getUsers())
  }, [])

  // * Get Users
  const getUsersInfo = useSelector((state) => state.getUsersInfo)
  const { loadingGetUsersInfo, errorGetUsersInfo, getUsersData } = getUsersInfo

  useEffect(() => {
    dispatch({ type: GET_USERS_RESET })
    if (getUsersData) {
      setLoading(false)
      setData(getUsersData.users)
      setCustomersFromServer(getUsersData.customers)
      setRolesFromServer(getUsersData.roles)
    } else if (errorGetUsersInfo) {
      setLoading(false)
      toast(errorGetUsersInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getUsersData, errorGetUsersInfo])

  useEffect(() => {
    if (rolesFromServer.length > 0) {
      let data = []
      rolesFromServer.forEach((item) => {
        data.push({ id: item._id, title: item.name })
      })
      setRoleOptions(data)
    }
  }, [rolesFromServer])

  useEffect(() => {
    if (customersFromServer.length > 0) {
      let data = []
      customersFromServer.forEach((item) => {
        data.push({ id: item._id, title: item.name })
      })
      setCustomerOptions(data)
    }
  }, [customersFromServer])

  // * Users Table
  const headCells = [
    {
      field: 'name',
      title: 'Name',
      render: (rowData) => (
        <Link
          className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
          to={`/users/${rowData._id}`}
        >
          {rowData.name}
        </Link>
      ),
    },
    {
      field: 'email',
      title: 'Email',
    },
    {
      field: 'identifier',
      title: 'Identifier',
    },
    {
      field: 'role.name',
      title: 'Role',
    },
    {
      field: 'customers.length',
      title: 'Customers',
    },
    {
      field: 'joinedOn',
      title: 'Joined On',
    },
  ]

  // * Search User by Email
  const searchByEmail = () => {
    if (search === '') {
      toast('Enter a valid email to search', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(getUserByEmail(search))
    }
  }

  const getUserByEmailInfo = useSelector((state) => state.getUserByEmailInfo)
  const {
    loadingGetUserByEmailInfo,
    errorGetUserByEmailInfo,
    getUserByEmailData,
  } = getUserByEmailInfo

  useEffect(() => {
    dispatch({ type: GET_USER_BY_EMAIL_RESET })
    if (getUserByEmailData) {
      setData([getUserByEmailData])
    } else if (errorGetUserByEmailInfo) {
      toast(errorGetUserByEmailInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getUserByEmailData, errorGetUserByEmailInfo])

  // * Open Add User Modal
  const openAddUserModal = () => {
    setOpenModal(true)
  }

  // * Close Add User Modal
  const closeAddUserModal = () => {
    setOpenModal(false)
    setEmail('')
    setIdentifier('')
    setName('')
    setRole('')
    setCustomers([])
    setIpAddress('')
  }

  // * Add User
  const addUserHandler = () => {
    if (!name || !identifier || !email || !role || !ipAddress) {
      toast('All the fields are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return
    }

    if (customers.length === 0) {
      toast('Select atleast 1 customer', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return
    }

    dispatch(addUser(name, identifier, email, role, customers, ipAddress))
  }

  const addUserInfo = useSelector((state) => state.addUserInfo)
  const { loadingAddUserInfo, errorAddUserInfo, addUserData } = addUserInfo

  useEffect(() => {
    dispatch({ type: ADD_USER_RESET })
    if (addUserData) {
      toast('User added successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setNewPassword(addUserData)
      setPasswordModal(true)
      closeAddUserModal()
    } else if (errorAddUserInfo) {
      toast(errorAddUserInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addUserData, errorAddUserInfo])

  const closePasswordModal = () => {
    setNewPassword('')
    setPasswordModal(false)
    setTimeout(() => {
      dispatch(getUsers())
    }, 1000)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className='w-full h-full'>
      <h1 className='text-2xl font-semibold'>Users</h1>
      <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
        <div className='flex justify-between'>
          <div className='flex gap-5'>
            <Input
              name='Search User by Email'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              type='button'
              onClick={searchByEmail}
              // disabled={loadingGetTaskByTaskIDInfo}
              text='Search'
            />
          </div>
          {addUserOption && (
            <Button
              type='button'
              onClick={openAddUserModal}
              text='Add a User'
            />
          )}
        </div>
      </div>
      <MaterialTable
        icons={tableIcons}
        title={''}
        columns={headCells}
        data={data}
        options={{
          exportButton: false,
          search: true,
          exportAllData: false,
          rowStyle: {
            height: '5px',
            fontSize: 13,
          },
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
          headerStyle: {
            position: 'sticky',
            top: '0',
          },
        }}
      />
      {openModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Add User</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <div className='flex gap-5 mb-4 w-full'>
                    <Input
                      width='flex-1'
                      name='Name *'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Input
                      width='flex-1'
                      name='Identifier *'
                      value={identifier}
                      onChange={(e) => setIdentifier(e.target.value)}
                    />
                  </div>
                  <Input
                    width='w-full mb-4'
                    name='Email *'
                    value={email}
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Select
                    width='w-full mb-4'
                    name='Role *'
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    options={roleOptions}
                  />
                  <Input
                    width='w-full mb-4'
                    name='IP Address *'
                    value={ipAddress}
                    onChange={(e) => setIpAddress(e.target.value)}
                  />
                  <p className='mb-2 text-sm'>Select Customers *</p>
                  <div class='flex flex-wrap md:items-center mb-4'>
                    {customerOptions.map((item) => (
                      <label class='w-1/2 text-gray-500 font-bold mt-2'>
                        <input
                          class='mr-2 leading-tight'
                          type='checkbox'
                          onChange={(e) =>
                            e.target.checked === true
                              ? setCustomers([...customers, item.id])
                              : removeItemsFromArray(item.id)
                          }
                        />
                        <span class='text-sm'>{item.title}</span>
                      </label>
                    ))}
                  </div>
                  <p className='text-sm'>All the fields with * are mandatory</p>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closeAddUserModal}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                    type='button'
                    onClick={addUserHandler}
                    disabled={loadingAddUserInfo}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
      {passwordModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>New Password</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 pb-2 flex-auto'>
                  <Input
                    width='w-full mb-4'
                    name='New Password *'
                    value={newPassword}
                    disabled
                  />
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closePasswordModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </div>
  )
}

export default UserList
