export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST'
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS'
export const GET_ROLE_FAIL = 'GET_ROLE_FAIL'
export const GET_ROLE_RESET = 'GET_ROLE_RESET'

export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST'
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS'
export const DASHBOARD_FAIL = 'DASHBOARD_FAIL'
export const DASHBOARD_RESET = 'DASHBOARD_RESET'

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'GET_USERS_FAIL'
export const GET_USERS_RESET = 'GET_USERS_RESET'

export const GET_USER_BY_EMAIL_REQUEST = 'GET_USER_BY_EMAIL_REQUEST'
export const GET_USER_BY_EMAIL_SUCCESS = 'GET_USER_BY_EMAIL_SUCCESS'
export const GET_USER_BY_EMAIL_FAIL = 'GET_USER_BY_EMAIL_FAIL'
export const GET_USER_BY_EMAIL_RESET = 'GET_USER_BY_EMAIL_RESET'

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAIL = 'ADD_USER_FAIL'
export const ADD_USER_RESET = 'ADD_USER_RESET'

export const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST'
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL'
export const GET_USER_BY_ID_RESET = 'GET_USER_BY_ID_RESET'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET'

export const RESET_DEVICE_ID_REQUEST = 'RESET_DEVICE_ID_REQUEST'
export const RESET_DEVICE_ID_SUCCESS = 'RESET_DEVICE_ID_SUCCESS'
export const RESET_DEVICE_ID_FAIL = 'RESET_DEVICE_ID_FAIL'
export const RESET_DEVICE_ID_RESET = 'RESET_DEVICE_ID_RESET'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET'

export const REMOVE_CUSTOMER_FROM_USER_REQUEST =
  'REMOVE_CUSTOMER_FROM_USER_REQUEST'
export const REMOVE_CUSTOMER_FROM_USER_SUCCESS =
  'REMOVE_CUSTOMER_FROM_USER_SUCCESS'
export const REMOVE_CUSTOMER_FROM_USER_FAIL = 'REMOVE_CUSTOMER_FROM_USER_FAIL'
export const REMOVE_CUSTOMER_FROM_USER_RESET = 'REMOVE_CUSTOMER_FROM_USER_RESET'

export const ADD_CUSTOMER_TO_USER_REQUEST = 'ADD_CUSTOMER_TO_USER_REQUEST'
export const ADD_CUSTOMER_TO_USER_SUCCESS = 'ADD_CUSTOMER_TO_USER_SUCCESS'
export const ADD_CUSTOMER_TO_USER_FAIL = 'ADD_CUSTOMER_TO_USER_FAIL'
export const ADD_CUSTOMER_TO_USER_RESET = 'ADD_CUSTOMER_TO_USER_RESET'

export const FETCH_ATTENDANCE_REQUEST = 'FETCH_ATTENDANCE_REQUEST'
export const FETCH_ATTENDANCE_SUCCESS = 'FETCH_ATTENDANCE_SUCCESS'
export const FETCH_ATTENDANCE_FAIL = 'FETCH_ATTENDANCE_FAIL'
export const FETCH_ATTENDANCE_RESET = 'FETCH_ATTENDANCE_RESET'

export const RESIGN_USERS_REQUEST = 'RESIGN_USERS_REQUEST'
export const RESIGN_USERS_SUCCESS = 'RESIGN_USERS_SUCCESS'
export const RESIGN_USERS_FAIL = 'RESIGN_USERS_FAIL'
export const RESIGN_USERS_RESET = 'RESIGN_USERS_RESET'

export const RESIGN_USER_REQUEST = 'RESIGN_USER_REQUEST'
export const RESIGN_USER_SUCCESS = 'RESIGN_USER_SUCCESS'
export const RESIGN_USER_FAIL = 'RESIGN_USER_FAIL'
export const RESIGN_USER_RESET = 'RESIGN_USER_RESET'

export const REJOIN_USER_REQUEST = 'REJOIN_USER_REQUEST'
export const REJOIN_USER_SUCCESS = 'REJOIN_USER_SUCCESS'
export const REJOIN_USER_FAIL = 'REJOIN_USER_FAIL'
export const REJOIN_USER_RESET = 'REJOIN_USER_RESET'

export const ATTENDANCE_REPORT_REQUEST = 'ATTENDANCE_REPORT_REQUEST'
export const ATTENDANCE_REPORT_SUCCESS = 'ATTENDANCE_REPORT_SUCCESS'
export const ATTENDANCE_REPORT_FAIL = 'ATTENDANCE_REPORT_FAIL'
export const ATTENDANCE_REPORT_RESET = 'ATTENDANCE_REPORT_RESET'

export const USERS_REPORT_REQUEST = 'USERS_REPORT_REQUEST'
export const USERS_REPORT_SUCCESS = 'USERS_REPORT_SUCCESS'
export const USERS_REPORT_FAIL = 'USERS_REPORT_FAIL'
export const USERS_REPORT_RESET = 'USERS_REPORT_RESET'
