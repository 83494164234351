import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import exportFromJSON from 'export-from-json'
import readXlsxFile from 'read-excel-file'
import { Link } from 'react-router-dom'
import moment from 'moment'

import {
  addTask,
  bulkUploadTasks,
  getTasks,
  searchTasks,
  updateTaskByID,
} from '../actions/taskActions'
import Input from '../components/Input'
import Button from '../components/Button'
import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Select from '../components/Select'
import {
  ADD_TASK_RESET,
  BULK_UPLOAD_TASKS_RESET,
  GET_TASKS_RESET,
  SEARCH_TASKS_RESET,
  UPDATE_TASK_BY_ID_RESET,
} from '../constants/taskConstants'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const TaskList = ({ history }) => {
  // * States
  // * Data
  const [data, setData] = useState([])
  const [users, setUsers] = useState([])
  const [usersOptions, setUsersOptions] = useState([])
  const [usersIdentifiers, setUsersIdentifiers] = useState([])
  const [pincodes, setPincodes] = useState([])
  const [search, setSearch] = useState('')
  // * Add or update task modal
  const [openModal, setOpenModal] = useState(false)
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [address, setAddress] = useState('')
  const [customer, setCustomer] = useState('')
  const [loanType, setLoanType] = useState('')
  const [branch, setBranch] = useState('')
  const [financeAmount, setFinanceAmount] = useState('')
  const [loanNumber, setLoanNumber] = useState('')
  const [type, setType] = useState('')
  const [receiptDate, setReceiptDate] = useState('')
  const [allocationRemarks, setAllocationRemarks] = useState('')
  const [assignedTo, setAssignedTo] = useState('')
  const [id, setId] = useState('')
  const [submitType, setSubmitType] = useState(null)
  const [status, setStatus] = useState(true)
  // * Customer options
  const [customerOptions, setCustomerOptions] = useState([])
  // * Upload tasks modal
  const [openUploadTasksModal, setOpenUploadTasksModal] = useState(false)
  const [file, setFile] = useState(null)
  const [validatedData, setValidatedData] = useState([])
  const [validatedDataTable, setValidatedDataTable] = useState(false)
  const [automateAllocation, setAutomateAllocation] = useState(false)

  const [addCustomerOption, setAddCustomerOption] = useState(false)
  const [addAllocationOption, setAddAllocationOption] = useState(false)
  const [detailsView, setDetailsView] = useState(false)
  const [updateOption, setUpdateOption] = useState(false)
  const [loading, setLoading] = useState(true)

  const [errorCases, setErrorCases] = useState([])

  // * Table
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [filterModal, setFilterModal] = useState(false)
  const [filterObj, setFilterObj] = useState({ status: '', caseType: '' })
  const [filteredData, setFilteredData] = useState([])
  const [order, setOrder] = useState('ASC')

  // * Table sorting by Task ID
  const sortingByTaskID = (col) => {
    if (order === 'ASC') {
      const sorted = [...filteredData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      )
      setFilteredData(sorted)
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const sorted = [...filteredData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      )
      setFilteredData(sorted)
      setOrder('ASC')
    }
  }

  // * Table sorting
  const sorting = (col) => {
    if (order === 'ASC') {
      const sorted = [...filteredData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      )
      setFilteredData(sorted)
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const sorted = [...filteredData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      )
      setFilteredData(sorted)
      setOrder('ASC')
    }
  }

  // * Initialization
  const dispatch = useDispatch()
  const exportType = exportFromJSON.types.csv

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      setAddCustomerOption(getRoleData.tasksAddCustomer)
      setAddAllocationOption(getRoleData.tasksAddAllocation)
      setDetailsView(getRoleData.tasksDetailsView)
      setUpdateOption(getRoleData.tasksUpdate)
      if (!getRoleData.tasksView) {
        history.push('/')
      }
    }
  }, [userInfo, getRoleData, history])

  // * Get Tasks
  useEffect(() => {
    dispatch(getTasks(page))
  }, [page])

  useEffect(() => {
    let data = []
    userInfo.customers.map((item) => {
      data.push({
        id: item._id,
        title: item.name,
      })
    })
    setCustomerOptions(data)
  }, [])

  // * Tasks
  const getTasksInfo = useSelector((state) => state.getTasksInfo)
  const { loadingGetTasksInfo, errorGetTasksInfo, getTasksData } = getTasksInfo

  useEffect(() => {
    dispatch({ type: GET_TASKS_RESET })
    if (getTasksData) {
      setLoading(false)
      setData(getTasksData.tasks)
      setPage(getTasksData.page)
      setPages(getTasksData.pages)
      setTotal(getTasksData.count)
      if (addAllocationOption) {
        setUsers(getTasksData.users)
        setPincodes(getTasksData.pincodes)
      }
    } else if (errorGetTasksInfo) {
      setLoading(false)
      toast(errorGetTasksInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getTasksData, errorGetTasksInfo])

  // * Users
  useEffect(() => {
    if (users.length > 0) {
      let data = []
      users.forEach((item) => {
        data.push({
          id: item._id,
          title: `${item.identifier} - ${item.name}`,
        })
      })
      setUsersOptions(data)
    }
  }, [users])

  // * Users Add Allocation
  useEffect(() => {
    if (users.length > 0 && customer !== '') {
      let data = []
      const filteredUsers = users.filter((item) => {
        return item.customers.includes(customer)
      })

      filteredUsers.forEach((obj) => {
        data.push(obj.identifier)
      })

      setUsersIdentifiers(data)
    }
  }, [users, customer])

  // * Open update task modal
  const updateTask = (item) => {
    setOpenModal(true)
    setName(item.name)
    setMobile(item.mobile)
    setAddress(item.address)
    setCustomer(item.customer._id)
    setType(item.type)
    setBranch(item.branch)
    setFinanceAmount(item.financeAmount)
    setLoanNumber(item.loanNumber)
    setLoanType(item.loanType)
    setReceiptDate(item.receiptDate)
    setAllocationRemarks(item.allocationRemarks)
    setAssignedTo(item.assignedTo)
    setId(item._id)
    setSubmitType('update')
    setStatus(
      item.status === 'Completed' || item.status === 'Finalisation Pending'
        ? false
        : true
    )
  }

  // * Tasks list table
  const headCellsAddCustomer = [
    {
      field: 'taskID',
      title: 'Task ID',
      render: (rowData) =>
        detailsView ? (
          <Link
            className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
            to={`/tasks/${rowData._id}`}
          >
            {rowData.taskID}
          </Link>
        ) : (
          <div
            className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
            onClick={() => updateTask(rowData)}
          >
            <p>{rowData.taskID}</p>
          </div>
        ),
    },
    {
      field: 'loanType',
      title: 'Loan Type',
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'name',
      title: 'Name',
    },
    
    {
      field: 'loanNumber',
      title: 'Loan Number',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'customerUserName',
      title: 'Allocated By Bank User',
    },
    {
      field: 'allocatedByBankOn',
      title: 'Allocated By Bank On',
    },
    {
      field: 'visitFinishedOn',
      title: 'Visit Finished On',
    },
  ]

  const headCellsAddAllocation = [
    {
      field: 'taskID',
      title: 'Task ID',
      render: (rowData) =>
        detailsView ? (
          <Link
            className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
            to={`/tasks/${rowData._id}`}
          >
            {rowData.taskID}
          </Link>
        ) : (
          <div
            className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
            onClick={() => updateTask(rowData)}
          >
            <p>{rowData.taskID}</p>
          </div>
        ),
      cellStyle: {
        minWidth: '100px',
      },
      headerStyle: {
        minWidth: '100px',
      },
    },

    {
      field: 'allocatedOnFieldOn',
      title: 'Allocated On Field On',
    },

    {
      field: 'assignedToName',
      title: 'Allocated On Field To',
    },
    {
      field: 'status',
      title: 'Status',
      lookup: {
        'Allocation Pending': 'Allocation Pending',
        'Visit Pending': 'Visit Pending',
        'In Progress': 'In Progress',
        'Finalisation Pending': 'Finalisation Pending',
        Completed: 'Completed',
      },
      cellStyle: {
        minWidth: '150px',
      },
      headerStyle: {
        minWidth: '150px',
      },
    },
    {
      field: 'customerName',
      title: 'Customer',
      cellStyle: {
        minWidth: '150px',
      },
      headerStyle: {
        minWidth: '150px',
      },
    },
    {
      field: 'loanType',
      title: 'Loan Type',
    },
    {
      field: 'type',
      title: 'Type',
      lookup: {
        Residence: 'Residence',
        Office: 'Office',
        Collections: 'Collections',
      },
      cellStyle: {
        minWidth: '150px',
      },
      headerStyle: {
        minWidth: '150px',
      },
    },
    {
      field: 'name',
      title: 'Name',
      cellStyle: {
        minWidth: '150px',
      },
      headerStyle: {
        minWidth: '150px',
      },
    },
    
    {
      field: 'loanNumber',
      title: 'Loan Number',
    },
    {
      field: 'customerUserName',
      title: 'Allocated By Bank User',
    },
    {
      field: 'allocatedByBankOn',
      title: 'Allocated By Bank On',
    },
    {
      field: 'assignedByName',
      title: 'Allocated On Field By',
    },
    
  
    
    {
      field: 'visitStartedOn',
      title: 'Visit Started On',
    },
    {
      field: 'visitFinishedOn',
      title: 'Visit Finished On',
    },
    {
      field: 'finalisationByName',
      title: 'Finalisation By',
    },
    {
      field: 'finalisationOn',
      title: 'Finalisation On',
    },
    {
      field: 'waivedOn',
      title: 'Waived On',
    },
  ]

  // * Open add task modal
  const openAddTaskModal = () => {
    setOpenModal(true)
    setSubmitType('add')
  }

  // * Close add task modal
  const closeAddTaskModal = () => {
    setOpenModal(false)
    // * Reset
    setName('')
    setMobile('')
    setAddress('')
    setCustomer('')
    setLoanType('')
    setBranch('')
    setFinanceAmount('')
    setLoanNumber('')
    setType('')
    setReceiptDate('')
    setAllocationRemarks('')
    setAssignedTo('')
    setId('')
    setSubmitType(null)
    setStatus(true)
  }

  // * Add Task
  const submitTaskHandler = () => {
    if (!name || !mobile || !address || !customer || !type) {
      toast('All the fields with * are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else if (addAllocationOption === true && !assignedTo) {
      toast('Please select a user from the dropdown to allocate to', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      // * If submit type is add
      if (submitType === 'add') {
        dispatch(
          addTask(
            name,
            mobile,
            address,
            customer,
            loanType,
            branch,
            financeAmount,
            loanNumber,
            type,
            receiptDate,
            allocationRemarks,
            addAllocationOption === true ? assignedTo : '' // * Only in case allocator
          )
        )
      } else {
        // * If submit type is update
        dispatch(
          updateTaskByID(
            id,
            name,
            mobile,
            address,
            loanType,
            branch,
            financeAmount,
            loanNumber,
            receiptDate,
            allocationRemarks,
            addAllocationOption === true ? assignedTo : '' // * Only in case allocator
          )
        )
      }
    }
  }

  const addTaskInfo = useSelector((state) => state.addTaskInfo)
  const { loadingAddTaskInfo, errorAddTaskInfo, addTaskData } = addTaskInfo

  useEffect(() => {
    dispatch({ type: ADD_TASK_RESET })
    if (addTaskData) {
      toast('Task added successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getTasks())
        closeAddTaskModal()
      }, 500)
    } else if (errorAddTaskInfo) {
      toast(errorAddTaskInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addTaskData, errorAddTaskInfo])

  const updateTaskByIDInfo = useSelector((state) => state.updateTaskByIDInfo)
  const {
    loadingUpdateTaskByIDInfo,
    errorUpdateTaskByIDInfo,
    updateTaskByIDData,
  } = updateTaskByIDInfo

  useEffect(() => {
    dispatch({ type: UPDATE_TASK_BY_ID_RESET })
    if (updateTaskByIDData) {
      toast('Task updated successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getTasks())
        closeAddTaskModal()
      }, 1000)
    } else if (errorUpdateTaskByIDInfo) {
      toast(errorUpdateTaskByIDInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [updateTaskByIDData, errorUpdateTaskByIDInfo])

  // * Download excel
  const downloadExcel = () => {
    const fileName = 'Tasks'

    const newData = []

    data.forEach((item) => {
      if (item.status === 'Visit Pending') {
        newData.push({
          Title: item.taskID,
          Type: item.type,
          'Start Time': '',
          'End Tine': '',
          'Assign To': '',
          Description: item.allocationRemarks,
          Priority: 'High',
          "Applicant's Name": item.name,
          'Bank Name': item.customerName,
          Verification: '',
          'Receipt Date': item.receiptDate,
          Branch: item.branch,
          'Request Number': '',
          'Reference Number': '',
          'FI Type': item.loanType,
          'Loan Number': item.loanNumber,
          'Finance Amount': item.financeAmount,
          'Customer Address': item.address,
        })
      }
    })

    exportFromJSON({ data: newData, fileName, exportType })
  }

  // * Upload excel
  const uploadExcel = () => {
    setOpenUploadTasksModal(true)
  }

  // * Close upload excel
  const closeUploadExcel = () => {
    setOpenUploadTasksModal(false)
    setFile(null)
    setCustomer('')
    setAutomateAllocation(false)
  }

  const headCellsForValidation = [
    {
      field: 'Status',
      title: 'Status',
    },
    {
      field: 'Name',
      title: 'Name',
    },
    {
      field: 'Mobile',
      title: 'Mobile',
    },
    {
      field: 'Address',
      title: 'Address',
    },
    {
      field: 'Case Type',
      title: 'Case Type',
    },
    {
      field: 'Branch',
      title: 'Branch',
    },
    {
      field: 'Finance Amount',
      title: 'Finance Amount',
    },
    {
      field: 'Loan Number',
      title: 'Loan Number',
    },
    {
      field: 'Loan Type',
      title: 'Loan Type',
    },
    {
      field: 'Receipt Date',
      title: 'Receipt Date',
    },
    {
      field: 'Allocation Remarks',
      title: 'Allocation Remarks',
    },
  ]

  const headCellsForValidationAllocation = [
    {
      field: 'Status',
      title: 'Status',
      editable: 'never',
    },
    {
      field: 'Name',
      title: 'Name',
      editable: 'never',
    },
    {
      field: 'Mobile',
      title: 'Mobile',
      editable: 'never',
    },
    {
      field: 'Address',
      title: 'Address',
      editable: 'never',
    },
    {
      field: 'Case Type',
      title: 'Case Type',
      editable: 'never',
    },
    {
      field: 'Branch',
      title: 'Branch',
      editable: 'never',
    },
    {
      field: 'Finance Amount',
      title: 'Finance Amount',
      editable: 'never',
    },
    {
      field: 'Loan Number',
      title: 'Loan Number',
      editable: 'never',
    },
    {
      field: 'Loan Type',
      title: 'Loan Type',
      editable: 'never',
    },
    {
      field: 'Receipt Date',
      title: 'Receipt Date',
      editable: 'never',
    },
    {
      field: 'Assign To',
      title: 'Assign To',
      lookup: {
        '': 'Allocate',
        T5354: 'T5354 - Manoj Bhatia',
        T0004: 'T0004 - Deepank Mehta',
      },
    },
    {
      field: 'Allocation Remarks',
      title: 'Allocation Remarks',
      editable: 'never',
    },
  ]

  // * Excel Validation
  const validateExcel = (customer, file) => {
    if (!customer) {
      toast('Please select a valid customer from the dropdown', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
      return
    }

    if (file) {
      readXlsxFile(file).then((rows) => {
        if (rows.length > 0) {
          const data = []

          let head = {}

          rows[0].forEach((item) => {
            head = { ...head, [item]: '' }
          })

          // * All rows
          for (let i = 1; i < rows.length; i++) {
            let obj = {}

            // * All cells
            rows[0].forEach((item, index) => {
              obj = { ...obj, [item]: rows[i][index] }
            })

            let status = ''

            if (!obj.Name) {
              status = 'Name is required, '
            }
            if (!obj.Mobile || obj.Mobile.toString().length !== 10) {
              status = status + 'Mobile must be of 10 digits, '
            }
            if (!obj.Address) {
              status = status + 'Address is required, '
            }
            if (!obj['Case Type']) {
              status = status + 'Case Type is required, '
            } else {
              if (
                obj['Case Type'] !== 'Residence' &&
                obj['Case Type'] !== 'Office' &&
                obj['Collections'] !== 'Collections'
              ) {
                status =
                  status +
                  'Case Type must be of type Residence, Office or Allocations'
              }
            }
            // * Check for Assign to if role is allocator
            if (addAllocationOption === true) {
              // * Check for automate allocation, if enabled then mark users accordingly from user's info and then check for assign to column and show a edit option with users list else continue with below mentioned code
              if (automateAllocation === true) {
                // * Pincode
                let resultResidence = obj['Address'].match(
                  /(^|[^\d])(\d{6})([^\d]|$)/
                )

                if (resultResidence !== null) {
                  // * Pincode
                  const pincodeResidence = resultResidence[2]

                  const pincodeFound = pincodes.find((item) => {
                    return item.pincode == pincodeResidence
                  })

                  if (pincodeFound) {
                    obj['Assign To'] = pincodeFound.user.identifier
                  } else {
                    obj['Assign To'] = ''
                  }
                }
              }

              if (!obj['Assign To']) {
                status = status + 'Assign To is required, '
              } else {
                // * Check if assign to user is in included in the users list
                if (!usersIdentifiers.includes(obj['Assign To'])) {
                  status = status + 'Assign To is not valid, '
                }
              }
            }

            if (status === '') {
              status = 'Success'
            }

            // * Receipt Data
            let receiptDate = moment(obj['Receipt Date']).format('DD-MM-YYYY')

            if (receiptDate == 'Invalid date') {
              receiptDate = ''
            }

            data.push({
              ...obj,
              Status: status,
              'Receipt Date': receiptDate,
              id: i,
            })
          }

          setValidatedData(data)
        }
      })
    } else {
      toast('Please upload an excel file', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }

  // * Show Validated Data Table
  useEffect(() => {
    if (validatedData.length > 0) {
      setOpenUploadTasksModal(false)
      setFile(null)
      setValidatedDataTable(true)
    }
  }, [validatedData])

  // * Close Validated Data Table
  const closeValidatedTable = () => {
    setValidatedDataTable(false)
    setValidatedData([])
    setCustomer('')
    setAutomateAllocation(false)
  }

  // * Process Validated Data
  const processData = () => {
    if (validatedData.length > 0) {
      let errors = 0
      validatedData.forEach((item) => {
        if (item.Status !== 'Success') {
          errors++
        }
      })

      if (errors > 0) {
        toast(
          'There are some errors in the data, please fix them all before uploading',
          {
            type: 'error',
            hideProgressBar: true,
            autoClose: 2000,
          }
        )
      } else {
        // * Process
        dispatch(bulkUploadTasks(customer, validatedData))
      }
    } else {
      toast('No data to process', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }

  const bulkUploadTasksInfo = useSelector((state) => state.bulkUploadTasksInfo)
  const {
    loadingBulkUploadTasksInfo,
    errorBulkUploadTasksInfo,
    addBulkUploadTasksData,
  } = bulkUploadTasksInfo

  useEffect(() => {
    dispatch({ type: BULK_UPLOAD_TASKS_RESET })
    if (addBulkUploadTasksData) {
      toast(addBulkUploadTasksData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setErrorCases(addBulkUploadTasksData.errorCounter)
      setTimeout(() => {
        dispatch(getTasks())
        closeValidatedTable()
      }, 1000)
    } else if (errorBulkUploadTasksInfo) {
      toast(errorBulkUploadTasksInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addBulkUploadTasksData, errorBulkUploadTasksInfo])

  useEffect(() => {
    if (data) {
      setFilteredData(data)
    }
  }, [data])

  const closeFilters = () => {
    setFilterObj({ status: '', caseType: '' })
    setFilterModal(false)
    setFilteredData(data)
  }

  const applyFilters = () => {
    let newData = []
    if (filterObj.status !== '') {
      newData = data.filter((item) => {
        return item.status === filterObj.status
      })
    } else {
      newData = data
    }

    if (filterObj.caseType !== '') {
      newData = newData.filter((item) => {
        return item.type === filterObj.caseType
      })
    }

    setFilteredData(newData)
    setFilterModal(false)
  }

  useEffect(() => {
    if (search !== '') {
      if (search.includes('/')) {
        toast('Please remove / from search field', {
          type: 'error',
          hideProgressBar: true,
          autoClose: 2000,
        })
        return
      } else {
        setTimeout(() => {
          dispatch(searchTasks(search))
        }, 100)
      }
    } else {
      dispatch(getTasks(1))
    }
  }, [search])

  const searchTasksInfo = useSelector((state) => state.searchTasksInfo)
  const { loadingSearchTasks, searchTasksData, errorSearchTasks } =
    searchTasksInfo

  useEffect(() => {
    dispatch({ type: SEARCH_TASKS_RESET })
    if (searchTasksData) {
      setFilteredData(searchTasksData)
    } else if (errorSearchTasks) {
      toast(errorSearchTasks, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [searchTasksData, errorSearchTasks])

  if (loading || loadingGetTasksInfo) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <h1 className='text-2xl font-semibold'>Tasks</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex justify-between h-10'>
            <Input
              width='flex-1 mr-5'
              name='Search by Task ID, Allocation Remark, Loan Number, Mobile Number or Name'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {addCustomerOption ||
              (addAllocationOption && (
                <div className='flex gap-5'>
                  {data.length > 0 && (
                    <>
                      <Button
                        type='button'
                        onClick={downloadExcel}
                        text='Download'
                      />
                      <Button
                        type='button'
                        onClick={() => setFilterModal(true)}
                        text='Filters'
                      />
                    </>
                  )}
                  <Button
                    type='button'
                    onClick={openAddTaskModal}
                    custom='py-2'
                    text='Add a Task'
                  />
                  <Button type='button' onClick={uploadExcel} text='Upload' />
                </div>
              ))}
          </div>
        </div>

        <div
          className='w-full bg-white'
          style={{ overflowX: 'auto', height: '85%' }}
        >
          <table
            className='w-full'
            style={{ height: '100%', position: 'relative' }}
          >
            <thead
              className='w-full bg-white'
              style={{ position: 'sticky', insetBlockStart: 0 }}
            >
              <tr className='w-full flex flex-row p-4 gap-2 items-center border border-b-1'>
                {addCustomerOption === false ? (
                  <>
                    {headCellsAddAllocation.map((item) => (
                      <>
                        {item.field === 'taskID' ? (
                          <th
                            className='w-24 flex items-center'
                            style={{ cursor: 'pointer' }}
                            key={item.field}
                            onClick={() => sortingByTaskID(item.field)}
                          >
                            <p className='text-sm font-semibold'>
                              {item.title}
                            </p>
                          </th>
                        ) : (
                          <th
                            className='w-36 flex items-center'
                            key={item.field}
                            style={{ cursor: 'pointer' }}
                            onClick={() => sorting(item.field)}
                          >
                            <p className='text-sm font-semibold'>
                              {item.title}
                            </p>
                          </th>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {headCellsAddCustomer.map((item) => (
                      <>
                        {item.field === 'taskID' ? (
                          <th
                            className='w-24 flex items-center'
                            key={item.field}
                            onClick={() => sortingByTaskID(item.field)}
                          >
                            <p className='text-sm font-semibold'>
                              {item.title}
                            </p>
                          </th>
                        ) : (
                          <th
                            className='w-36 flex items-center'
                            key={item.field}
                            style={{ cursor: 'pointer' }}
                            onClick={() => sorting(item.field)}
                          >
                            <p className='text-sm font-semibold'>
                              {item.title}
                            </p>
                          </th>
                        )}
                      </>
                    ))}
                  </>
                )}
              </tr>
            </thead>
            <tbody style={{ overflowY: 'auto', height: '60%' }}>
              {filteredData.map((item, index) => (
                <tr
                  key={item.index}
                  className='w-full flex flex-row p-4 gap-2 items-center border border-b-1'
                >
                  {addCustomerOption === false ? (
                    <>
                      {headCellsAddAllocation.map((head) => {
                        return (
                          <>
                            {head.field === 'taskID' ? (
                              <td className='w-24 flex items-center'>
                                {detailsView ? (
                                  <>
                                    {item.information &&
                                    item.information.reason === 'Transfer' ? (
                                      <>
                                        {item.customer.openTransfers ===
                                        true ? (
                                          <Link
                                            className='font-bold text-md cursor-pointer text-red-800 bg-red-100 p-2 flex justify-center rounded'
                                            to={`/tasks/${item._id}`}
                                          >
                                            <p className='text-sm'>
                                              {item[head.field]}
                                            </p>
                                          </Link>
                                        ) : (
                                          <div className='font-bold text-md p-2 flex justify-center rounded bg-red-100 text-red-800'>
                                            <p className='text-sm'>
                                              {item[head.field]}
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    ) : item.status === 'Waived' ? (
                                      <div className='font-bold text-md p-2 flex justify-center rounded bg-yellow-100 text-yellow-800'>
                                        <p className='text-sm'>
                                          {item[head.field]}
                                        </p>
                                      </div>
                                    ) : (
                                      <Link
                                        className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
                                        to={`/tasks/${item._id}`}
                                      >
                                        <p className='text-sm'>
                                          {item[head.field]}
                                        </p>
                                      </Link>
                                    )}
                                  </>
                                ) : (
                                  <div
                                    className={`font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded ${
                                      item.information &&
                                      item.information.reason &&
                                      `bg-red-100 text-red-800`
                                    }`}
                                    onClick={() => updateTask(item)}
                                  >
                                    <p className='text-sm'>
                                      {item[head.field]}
                                    </p>
                                  </div>
                                )}
                              </td>
                            ) : (
                              <td className='w-36 flex items-center'>
                                <p className='text-sm'>{item[head.field]}</p>
                              </td>
                            )}
                          </>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {headCellsAddCustomer.map((head) => {
                        return (
                          <>
                            {head.field === 'taskID' ? (
                              <td className='w-24 flex items-center'>
                                {detailsView ? (
                                  <>
                                    {item.information &&
                                    item.information.reason === 'Transfer' ? (
                                      <>
                                        {item.customer.openTransfers ===
                                        true ? (
                                          <Link
                                            className='font-bold text-md cursor-pointer text-red-800 bg-red-100 p-2 flex justify-center rounded'
                                            to={`/tasks/${item._id}`}
                                          >
                                            <p className='text-sm'>
                                              {item[head.field]}
                                            </p>
                                          </Link>
                                        ) : (
                                          <div className='font-bold text-md p-2 flex justify-center rounded bg-red-100 text-red-800'>
                                            <p className='text-sm'>
                                              {item[head.field]}
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    ) : item.status === 'Waived' ? (
                                      <div className='font-bold text-md p-2 flex justify-center rounded bg-yellow-100 text-yellow-800'>
                                        <p className='text-sm'>
                                          {item[head.field]}
                                        </p>
                                      </div>
                                    ) : (
                                      <Link
                                        className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
                                        to={`/tasks/${item._id}`}
                                      >
                                        <p className='text-sm'>
                                          {item[head.field]}
                                        </p>
                                      </Link>
                                    )}
                                  </>
                                ) : (
                                  <div
                                    className={`font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded ${
                                      item.information &&
                                      item.information.reason &&
                                      `bg-red-100 text-red-800`
                                    }`}
                                    onClick={() => updateTask(item)}
                                  >
                                    <p className='text-sm'>
                                      {item[head.field]}
                                    </p>
                                  </div>
                                )}
                              </td>
                            ) : (
                              <td className='w-36 flex items-center'>
                                <p className='text-sm'>{item[head.field]}</p>
                              </td>
                            )}
                          </>
                        )
                      })}
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className='w-full bg-white border border-t-1 flex flex-row justify-between p-4'
            style={{ position: 'sticky', insetBlockEnd: 0 }}
          >
          
            <div className='flex flex-row items-center gap-2'>
              <button
                className='border border-1 px-1 border-black text-sm'
                style={{ cursor: 'pointer' }}
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
              >
                &#171;
              </button>
              <button
                className='border border-1 px-1 border-black text-sm'
                style={{ cursor: 'pointer' }}
                onClick={() => setPage(page + 1)}
                disabled={page === pages}
              >
                &#187;
              </button>
            </div>
          </div>
        </div>
        {/* <MaterialTable
          icons={tableIcons}
          title={''}
          columns={
            addCustomerOption === true
              ? headCellsAddCustomer
              : headCellsAddAllocation
          }
          data={data}
          options={{
            exportButton: false,
            search: true,
            exportAllData: false,
            rowStyle: {
              height: '5px',
              fontSize: 13,
            },
            paging: true,
            pageSize: 10,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 50],
            headerStyle: {
              position: 'sticky',
              top: '0',
            },
            filtering: addCustomerOption === true ? false : true,
            // paginationType: 'stepped',
          }}
        /> */}
      </div>

      {openModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Add Task</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <div className='flex gap-5 mb-4'>
                    <Input
                      name='Name *'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Input
                      name='Mobile *'
                      type='number'
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </div>
                  <Input
                    width='w-full mb-4'
                    name='Address *'
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <div className='flex gap-5 mb-4'>
                    <Select
                      name='Customer *'
                      value={customer}
                      onChange={(e) => setCustomer(e.target.value)}
                      options={customerOptions}
                    />
                    <Select
                      name='Case Type *'
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      options={[
                        { id: 'Residence', title: 'Residence' },
                        { id: 'Office', title: 'Office' },
                        { id: 'Collections', title: 'Collections' },
                      ]}
                    />
                  </div>
                  {addAllocationOption && (
                    <Select
                      width='w-full mb-4'
                      name='User *'
                      value={assignedTo}
                      onChange={(e) => setAssignedTo(e.target.value)}
                      options={usersOptions}
                    />
                  )}
                  <div className='flex gap-5 mb-4'>
                    <Input
                      name='Branch'
                      value={branch}
                      onChange={(e) => setBranch(e.target.value)}
                    />
                    <Input
                      name='Finance Amount'
                      type='number'
                      value={financeAmount}
                      onChange={(e) => setFinanceAmount(e.target.value)}
                    />
                  </div>
                  <div className='flex gap-5 mb-4'>
                    <Input
                      name='Loan Number'
                      value={loanNumber}
                      onChange={(e) => setLoanNumber(e.target.value)}
                    />
                    <Input
                      name='Loan Type'
                      value={loanType}
                      onChange={(e) => setLoanType(e.target.value)}
                    />
                  </div>
                  <Input
                    width='w-full mb-4'
                    name='Receipt Date'
                    value={receiptDate}
                    onChange={(e) => setReceiptDate(e.target.value)}
                  />
                  <textarea
                    className='block appearance-none w-full bg-gray-100 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm'
                    placeholder='Allocation Remarks'
                    rows={10}
                    onChange={(e) => setAllocationRemarks(e.target.value)}
                  >
                    {allocationRemarks}
                  </textarea>
                  <p className='text-sm mt-4'>
                    All the fields with * are mandatory
                  </p>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closeAddTaskModal}
                  >
                    Close
                  </button>
                  {submitType === 'add' ? (
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={submitTaskHandler}
                      disabled={loadingAddTaskInfo}
                    >
                      Add
                    </button>
                  ) : (
                    submitType === 'update' && (
                      <button
                        className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                        type='button'
                        onClick={submitTaskHandler}
                        disabled={
                          !updateOption || loadingUpdateTaskByIDInfo || !status
                        }
                      >
                        Update
                      </button>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
      {openUploadTasksModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Upload Tasks</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <Select
                    width='w-full mb-4'
                    name='Customer *'
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                    options={customerOptions}
                  />
                  <Input
                    type='file'
                    width='w-full mb-4'
                    name='Upload *'
                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <label
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      style={{ marginRight: '8px' }}
                      type='checkbox'
                      onChange={(e) =>
                        e.target.checked === true
                          ? setAutomateAllocation(true)
                          : setAutomateAllocation(false)
                      }
                      checked={automateAllocation === true ? true : false}
                    />
                    <span
                      style={{
                        color: 'black',
                        fontFamily: 'Arial, sans-serif',
                        fontStyle: 'normal',
                        textDecoration: 'none',
                        fontSize: 14,
                        textAlign: 'center',
                      }}
                    >
                      Automate?
                    </span>
                  </label>
                  <p className='text-sm mt-4'>
                    All the fields with * are mandatory
                  </p>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closeUploadExcel}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                    type='button'
                    onClick={() => validateExcel(customer, file)}
                  >
                    Validate
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
      {validatedDataTable && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-4xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Upload Tasks</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <MaterialTable
                    icons={tableIcons}
                    title={''}
                    columns={
                      addAllocationOption === true
                        ? headCellsForValidationAllocation
                        : headCellsForValidation
                    }
                    data={validatedData}
                    options={{
                      exportButton: false,
                      search: true,
                      exportAllData: false,
                      rowStyle: {
                        height: '5px',
                        fontSize: 13,
                      },
                      paging: true,
                      pageSize: 10,
                      emptyRowsWhenPaging: false,
                      pageSizeOptions: [10, 20, 50],
                      headerStyle: {
                        position: 'sticky',
                        top: '0',
                      },
                      minBodyHeight: '400px',
                      maxBodyHeight: '400px',
                    }}
                    cellEditable={
                      automateAllocation === true
                        ? {
                            onCellEditApproved: (
                              newValue,
                              oldValue,
                              rowData,
                              columnDef
                            ) => {
                              return new Promise((resolve, reject) => {
                                console.log('newValue: ' + newValue)

                                const dataWithoutRow = validatedData.filter(
                                  (item) => item.id !== rowData.id
                                )

                                dataWithoutRow.push({
                                  Status:
                                    rowData.Status === 'Assign To is required, '
                                      ? 'Success'
                                      : rowData.Status,
                                  Name: rowData.Name,
                                  Address: rowData.Address,
                                  'Allocation Remarks':
                                    rowData['Allocation Remarks'],
                                  'Assign To': newValue,
                                  Branch: rowData.Branch,
                                  'Case Type': rowData['Case Type'],
                                  'Finance Amount': rowData['Finance Amount'],
                                  'Loan Number': rowData['Loan Number'],
                                  'Loan Type': rowData['Loan Type'],
                                  
                                  'Receipt Date': rowData['Receipt Date'],
                                })

                                setValidatedData(dataWithoutRow)

                                setTimeout(resolve, 1000)
                              })
                            },
                          }
                        : null
                    }
                  />
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closeValidatedTable}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                    type='button'
                    onClick={processData}
                    disabled={loadingAddTaskInfo}
                  >
                    Process
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
      {errorCases && errorCases.length > 0 && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-4xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Error Tasks</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <MaterialTable
                    icons={tableIcons}
                    title={''}
                    columns={[
                      { field: 'name', title: 'Name' },
                      
                      { field: 'address', title: 'Address' },
                      { field: 'type', title: 'Case Type' },
                      { field: 'branch', title: 'Branch' },
                      { field: 'financeAmount', title: 'Finance Amount' },
                      { field: 'loanNumber', title: 'Loan Number' },
                      { field: 'loanNumber', title: 'Loan Number' },
                      { field: 'loanType', title: 'Loan Type' },
                      { field: 'receiptDate', title: 'Receipt Date' },
                      { field: 'assignTo', title: 'Assign To' },
                      {
                        field: 'allocationRemarks',
                        title: 'Allocation Remarks',
                      },
                    ]}
                    data={errorCases}
                    options={{
                      exportButton: true,
                      search: true,
                      exportAllData: true,
                      rowStyle: {
                        height: '5px',
                        fontSize: 13,
                      },
                      paging: true,
                      pageSize: 10,
                      emptyRowsWhenPaging: false,
                      pageSizeOptions: [10, 20, 50],
                      headerStyle: {
                        position: 'sticky',
                        top: '0',
                      },
                    }}
                  />
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setErrorCases([])}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
      {filterModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Filters</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <Select
                    width='w-full mb-4'
                    name='Status *'
                    value={filterObj.status}
                    onChange={(e) =>
                      setFilterObj({ ...filterObj, status: e.target.value })
                    }
                    options={[
                      { id: 'Allocation Pending', title: 'Allocation Pending' },
                      { id: 'Visit Pending', title: 'Visit Pending' },
                      { id: 'In Progress', title: 'In Progress' },
                      {
                        id: 'Finalisation Pending',
                        title: 'Finalisation Pending',
                      },
                      { id: 'Completed', title: 'Completed' },
                      { id: 'Waived', title: 'Waived' },
                    ]}
                  />
                  <Select
                    width='w-full mb-4'
                    name='Case Type *'
                    value={filterObj.caseType}
                    onChange={(e) =>
                      setFilterObj({ ...filterObj, caseType: e.target.value })
                    }
                    options={[
                      { id: 'Residence', title: 'Residence' },
                      { id: 'Office', title: 'Office' },
                      { id: 'Collections', title: 'Collections' },
                    ]}
                  />
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closeFilters}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                    type='button'
                    onClick={() => applyFilters(customer, file)}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </>
  )
}

export default TaskList
