import axios from 'axios'
import {
  ADD_TASK_FAIL,
  ADD_TASK_REQUEST,
  ADD_TASK_SUCCESS,
  BULK_UPLOAD_TASKS_FAIL,
  BULK_UPLOAD_TASKS_REQUEST,
  BULK_UPLOAD_TASKS_SUCCESS,
  DIAL_FAIL,
  DIAL_REQUEST,
  DIAL_SUCCESS,
  GET_TASKS_FAIL,
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  GET_TASK_BY_ID_FAIL,
  GET_TASK_BY_ID_REQUEST,
  GET_TASK_BY_ID_SUCCESS,
  GET_TRANSFERS_FAIL,
  GET_TRANSFERS_REQUEST,
  GET_TRANSFERS_SUCCESS,
  SEARCH_TASKS_FAIL,
  SEARCH_TASKS_REQUEST,
  SEARCH_TASKS_SUCCESS,
  SEARCH_TRANSFERS_FAIL,
  SEARCH_TRANSFERS_REQUEST,
  SEARCH_TRANSFERS_SUCCESS,
  TASKS_REPORT_FAIL,
  TASKS_REPORT_REQUEST,
  TASKS_REPORT_SUCCESS,
  TASK_CHECKED_FAIL,
  TASK_CHECKED_REQUEST,
  TASK_CHECKED_SUCCESS,
  TASK_FINALISATION_FAIL,
  TASK_FINALISATION_REQUEST,
  TASK_FINALISATION_SUCCESS,
  TASK_UPDATE_REMARKS_FAIL,
  TASK_UPDATE_REMARKS_REQUEST,
  TASK_UPDATE_REMARKS_SUCCESS,
  TASK_UPDATE_SUBMIT_FAIL,
  TASK_UPDATE_SUBMIT_REQUEST,
  TASK_UPDATE_SUBMIT_SUCCESS,
  TRANSFER_FAIL,
  TRANSFER_REQUEST,
  TRANSFER_SUCCESS,
  UPDATE_TASK_BY_ID_FAIL,
  UPDATE_TASK_BY_ID_REQUEST,
  UPDATE_TASK_BY_ID_SUCCESS,
  WAIVE_TASK_FAIL,
  WAIVE_TASK_REQUEST,
  WAIVE_TASK_SUCCESS,
} from '../constants/taskConstants'
import { logout } from './userActions'

export const getTasks = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TASKS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/tasks?page=${page}`, config)

    dispatch({
      type: GET_TASKS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_TASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addTask =
  (
    name,
    mobile,
    address,
    customer,
    loanType,
    branch,
    financeAmount,
    loanNumber,
    type,
    receiptDate,
    allocationRemarks,
    assignedTo // * Only if role is allocator
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_TASK_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/tasks`,
        {
          name,
          mobile,
          address,
          customer,
          loanType,
          branch,
          financeAmount,
          loanNumber,
          type,
          receiptDate,
          allocationRemarks,
          assignedTo,
        },
        config
      )

      dispatch({
        type: ADD_TASK_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: ADD_TASK_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getTaskByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TASK_BY_ID_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/tasks/${id}`, config)

    dispatch({
      type: GET_TASK_BY_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_TASK_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateTaskByID =
  (
    id,
    name,
    mobile,
    address,
    loanType,
    branch,
    financeAmount,
    type,
    loanNumber,
    receiptDate,
    allocationRemarks,
    assignedTo // * Only if role is allocator
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_TASK_BY_ID_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/tasks/${id}`,
        {
          name,
          mobile,
          address,
          loanType,
          branch,
          financeAmount,
          type,
          loanNumber,
          receiptDate,
          allocationRemarks,
          assignedTo,
        },
        config
      )

      dispatch({
        type: UPDATE_TASK_BY_ID_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: UPDATE_TASK_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const bulkUploadTasks =
  (customer, rows) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BULK_UPLOAD_TASKS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/tasks/bulk-upload`,
        {
          customer,
          rows,
        },
        config
      )

      dispatch({
        type: BULK_UPLOAD_TASKS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: BULK_UPLOAD_TASKS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const taskChecked = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TASK_CHECKED_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/tasks/${id}/checked`, {}, config)

    dispatch({
      type: TASK_CHECKED_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: TASK_CHECKED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateSubmitTask = (id, details) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TASK_UPDATE_SUBMIT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/tasks/update-submit/${id}`,
      details,
      config
    )

    dispatch({
      type: TASK_UPDATE_SUBMIT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: TASK_UPDATE_SUBMIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateTaskRemarks =
  (id, remarks) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TASK_UPDATE_REMARKS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/tasks/update-remarks/${id}`,
        { remarks },
        config
      )

      dispatch({
        type: TASK_UPDATE_REMARKS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: TASK_UPDATE_REMARKS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const taskFinalisation =
  (id, finalisationStatus, finalisationRemarks) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: TASK_FINALISATION_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/tasks/finalize/${id}`,
        { finalisationStatus, finalisationRemarks },
        config
      )

      dispatch({
        type: TASK_FINALISATION_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: TASK_FINALISATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getTransfers = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TRANSFERS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/tasks/transfers?page=${page}`, config)

    dispatch({
      type: GET_TRANSFERS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_TRANSFERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const transfer = (id, assignedTo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRANSFER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/tasks/transfers`,
      { id, assignedTo },
      config
    )

    dispatch({
      type: TRANSFER_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: TRANSFER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const dial = (id, number) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DIAL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/tasks/dial/${id}/web`,
      { number },
      config
    )

    dispatch({
      type: DIAL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: DIAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const tasks = (startDate, endDate) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TASKS_REPORT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/tasks/reports/tasks?startDate=${startDate}&endDate=${endDate}`,
      config
    )

    dispatch({
      type: TASKS_REPORT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: TASKS_REPORT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const searchTasks = (search) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SEARCH_TASKS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/tasks/search/${search}`, config)

    dispatch({
      type: SEARCH_TASKS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: SEARCH_TASKS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const searchTransfers = (search) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SEARCH_TRANSFERS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/tasks/transfers/search/${search}`,
      config
    )

    dispatch({
      type: SEARCH_TRANSFERS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: SEARCH_TRANSFERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const waive = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WAIVE_TASK_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/tasks/${id}/waive`, {}, config)

    dispatch({
      type: WAIVE_TASK_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: WAIVE_TASK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
