export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST'
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const GET_CUSTOMERS_FAIL = 'GET_CUSTOMERS_FAIL'
export const GET_CUSTOMERS_RESET = 'GET_CUSTOMERS_RESET'

export const ADD_CUSTOMER_REQUEST = 'ADD_CUSTOMER_REQUEST'
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS'
export const ADD_CUSTOMER_FAIL = 'ADD_CUSTOMER_FAIL'
export const ADD_CUSTOMER_RESET = 'ADD_CUSTOMER_RESET'

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAIL = 'UPDATE_CUSTOMER_FAIL'
export const UPDATE_CUSTOMER_RESET = 'UPDATE_CUSTOMER_RESET'
