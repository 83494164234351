import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import {
  addCustomerToUserReducer,
  addUserReducer,
  attendanceReportReducer,
  dashboardReducer,
  fetchAttendanceReducer,
  getRoleReducer,
  getUserByEmailReducer,
  getUserByIDReducer,
  getUsersReducer,
  rejoinUserReducer,
  removeCustomerFromUserReducer,
  resetDeviceIDReducer,
  resetPasswordReducer,
  resignUserReducer,
  resignUsersReducer,
  updateUserReducer,
  userLoginReducer,
  usersReportReducer,
} from './reducers/userReducers'
import {
  addCustomerReducer,
  getCustomersReducer,
  updateCustomerReducer,
} from './reducers/customerReducers'
import {
  addRoleReducer,
  getRoleByIDReducer,
  getRolesReducer,
  updateRoleReducer,
} from './reducers/roleReducers'
import {
  addTaskReducer,
  bulkUploadTasksReducer,
  dialReducer,
  getTaskByIDReducer,
  getTasksReducer,
  getTransfersReducer,
  searchTasksReducer,
  searchTransfersReducer,
  taskCheckedReducer,
  taskFinalisationReducer,
  tasksReportReducer,
  taskUpdateRemarksReducer,
  taskUpdateSubmitReducer,
  transferReducer,
  updateTaskByIDReducer,
  waiveTaskReducer,
} from './reducers/taskReducers'
import {
  currentAppVersionReducer,
  settingCallingDistanceReducer,
  settingReducer,
} from './reducers/settingReducers'
import {
  addPincodeReducer,
  getPincodesReducer,
  updatePincodeReducer,
} from './reducers/pincodeReducers'

const reducer = combineReducers({
  userLogin: userLoginReducer,
  getRoleInfo: getRoleReducer,
  getCustomersInfo: getCustomersReducer,
  addCustomerInfo: addCustomerReducer,
  updateCustomerInfo: updateCustomerReducer,
  getRolesInfo: getRolesReducer,
  addRoleInfo: addRoleReducer,
  getRoleByIDInfo: getRoleByIDReducer,
  updateRoleInfo: updateRoleReducer,
  getTasksInfo: getTasksReducer,
  addTaskInfo: addTaskReducer,
  getTaskByIDInfo: getTaskByIDReducer,
  updateTaskByIDInfo: updateTaskByIDReducer,
  bulkUploadTasksInfo: bulkUploadTasksReducer,
  taskCheckedInfo: taskCheckedReducer,
  taskUpdateSubmitInfo: taskUpdateSubmitReducer,
  taskUpdateRemarksInfo: taskUpdateRemarksReducer,
  taskFinalisationInfo: taskFinalisationReducer,
  dashboardInfo: dashboardReducer,
  getUsersInfo: getUsersReducer,
  getUserByEmailInfo: getUserByEmailReducer,
  addUserInfo: addUserReducer,
  getUserByIDInfo: getUserByIDReducer,
  updateUserInfo: updateUserReducer,
  resetDeviceIDInfo: resetDeviceIDReducer,
  resetPasswordInfo: resetPasswordReducer,
  removeCustomerFromUserInfo: removeCustomerFromUserReducer,
  addCustomerToUserInfo: addCustomerToUserReducer,
  fetchAttendanceInfo: fetchAttendanceReducer,
  getTransfersInfo: getTransfersReducer,
  transferInfo: transferReducer,
  resignUsersInfo: resignUsersReducer,
  resignUserInfo: resignUserReducer,
  rejoinUserInfo: rejoinUserReducer,
  dialInfo: dialReducer,
  attendanceReportInfo: attendanceReportReducer,
  tasksReportInfo: tasksReportReducer,
  usersReportInfo: usersReportReducer,
  settingInfo: settingReducer,
  settingCallingDistanceInfo: settingCallingDistanceReducer,
  getPincodesInfo: getPincodesReducer,
  addPincodeInfo: addPincodeReducer,
  updatePincodeInfo: updatePincodeReducer,
  currentAppVersionInfo: currentAppVersionReducer,
  searchTasksInfo: searchTasksReducer,
  searchTransfersInfo: searchTransfersReducer,
  waiveTaskInfo: waiveTaskReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const inititalState = {
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  inititalState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
