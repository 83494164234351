import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import Input from '../components/Input'
import Button from '../components/Button'
import Loading from '../components/Loading'
import { getRoleByID, updateRole } from '../actions/roleActions'
import {
  GET_ROLE_BY_ID_RESET,
  UPDATE_ROLE_RESET,
} from '../constants/roleConstants'

const RoleByID = ({ history }) => {
  let params = useParams()
  let id = ''
  if (params.id) {
    id = params.id.replace(':', '')
  }

  // * States
  const [name, setName] = useState('')
  const [tasksView, setTasksView] = useState(false)
  const [tasksDetailsView, setTasksDetailsView] = useState(false)
  const [tasksUpdate, setTasksUpdate] = useState(false)
  const [tasksAddCustomer, setTasksAddCustomer] = useState(false)
  const [tasksAddAllocation, setTasksAddAllocation] = useState(false)
  const [allocationPendingTasks, setAllocationPendingTasks] = useState(false)
  const [visitPendingTasks, setVisitPendingTasks] = useState(false)
  const [inProgressTasks, setInProgressTasks] = useState(false)
  const [finalisationPendingTasks, setFinalisationPendingTasks] =
    useState(false)
  const [completedTasks, setCompletedTasks] = useState(false)
  const [waivedTasks, setWaivedTasks] = useState(false)
  const [calling, setCalling] = useState(false)
  const [taskSummary, setTaskSummary] = useState(false)
  const [transfers, setTransfers] = useState(false)
  const [usersView, setUsersView] = useState(false)
  const [usersAdd, setUsersAdd] = useState(false)
  const [usersUpdate, setUsersUpdate] = useState(false)
  const [resignedUsers, setResignedUsers] = useState(false)
  const [rolesView, setRolesView] = useState(false)
  const [rolesAdd, setRolesAdd] = useState(false)
  const [rolesUpdate, setRolesUpdate] = useState(false)
  const [customersView, setCustomersView] = useState(false)
  const [customersAdd, setCustomersAdd] = useState(false)
  const [customersUpdate, setCustomersUpdate] = useState(false)
  const [appAccess, setAppAccess] = useState(false)
  const [webAccess, setWebAccess] = useState(false)
  const [settings, setSettings] = useState(false)
  const [reports, setReports] = useState(false)
  const [pincodes, setPincodes] = useState(false)
  const [dashboard, setDashboard] = useState(false)

  const [loading, setLoading] = useState(true)

  // * Tasks
  const tasksAddByCustomer = () => {
    setTasksAddCustomer(true)
    setTasksAddAllocation(false)
  }

  const tasksAddByAllocator = () => {
    setTasksAddCustomer(false)
    setTasksAddAllocation(true)
  }

  const noTasksView = () => {
    setTasksView(false)
    setAllocationPendingTasks(false)
    setVisitPendingTasks(false)
    setInProgressTasks(false)
    setFinalisationPendingTasks(false)
    setCompletedTasks(false)
    setTaskSummary(false)
  }

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.rolesUpdate) {
        history.push('/')
      }
    }
  }, [userInfo, getRoleData, history])

  useEffect(() => {
    dispatch(getRoleByID(id))
  }, [])

  // * Get Role By ID
  const getRoleByIDInfo = useSelector((state) => state.getRoleByIDInfo)
  const { loadingGetRoleByIDInfo, errorGetRoleByIDInfo, getRoleByIDData } =
    getRoleByIDInfo

  useEffect(() => {
    dispatch({ type: GET_ROLE_BY_ID_RESET })
    if (getRoleByIDData) {
      setLoading(false)
      setName(getRoleByIDData.name)
      setTasksView(getRoleByIDData.tasksView)
      setTasksUpdate(getRoleByIDData.tasksUpdate)
      setTasksDetailsView(getRoleByIDData.tasksDetailsView)
      setTasksAddCustomer(getRoleByIDData.tasksAddCustomer)
      setTasksAddAllocation(getRoleByIDData.tasksAddAllocation)
      setAllocationPendingTasks(getRoleByIDData.allocationPendingTasks)
      setVisitPendingTasks(getRoleByIDData.visitPendingTasks)
      setInProgressTasks(getRoleByIDData.inProgressTasks)
      setFinalisationPendingTasks(getRoleByIDData.finalisationPendingTasks)
      setCompletedTasks(getRoleByIDData.completedTasks)
      setWaivedTasks(getRoleByIDData.waivedTasks)
      setCalling(getRoleByIDData.calling)
      setTaskSummary(getRoleByIDData.taskSummary)
      setTransfers(getRoleByIDData.transfers)
      setUsersView(getRoleByIDData.usersView)
      setUsersAdd(getRoleByIDData.usersAdd)
      setUsersUpdate(getRoleByIDData.usersUpdate)
      setResignedUsers(getRoleByIDData.resignedUsers)
      setRolesView(getRoleByIDData.rolesView)
      setRolesAdd(getRoleByIDData.rolesAdd)
      setRolesUpdate(getRoleByIDData.rolesUpdate)
      setCustomersView(getRoleByIDData.customersView)
      setCustomersAdd(getRoleByIDData.customersAdd)
      setCustomersUpdate(getRoleByIDData.customersUpdate)
      setAppAccess(getRoleByIDData.appAccess)
      setWebAccess(getRoleByIDData.webAccess)
      setSettings(getRoleByIDData.settings)
      setReports(getRoleByIDData.reports)
      setPincodes(getRoleByIDData.pincodes)
      setDashboard(getRoleByIDData.dashboard)
    } else if (errorGetRoleByIDInfo) {
      setLoading(false)
      if (errorGetRoleByIDInfo.includes('Cast')) {
        history.push('/')
        return
      }
      toast(errorGetRoleByIDInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getRoleByIDData, errorGetRoleByIDInfo])

  // * Update Role
  const updateRoleHandler = () => {
    if (id === '') {
      toast('Role ID is mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(
        updateRole(
          id,
          tasksView,
          tasksDetailsView,
          tasksUpdate,
          tasksAddCustomer,
          tasksAddAllocation,
          allocationPendingTasks,
          visitPendingTasks,
          inProgressTasks,
          finalisationPendingTasks,
          completedTasks,
          waivedTasks,
          calling,
          taskSummary,
          transfers,
          usersView,
          usersAdd,
          usersUpdate,
          resignedUsers,
          rolesView,
          rolesAdd,
          rolesUpdate,
          customersView,
          customersAdd,
          customersUpdate,
          appAccess,
          webAccess,
          settings,
          reports,
          pincodes,
          dashboard
        )
      )
    }
  }

  const updateRoleInfo = useSelector((state) => state.updateRoleInfo)
  const { loadingUpdateRoleInfo, errorUpdateRoleInfo, updateRoleData } =
    updateRoleInfo

  useEffect(() => {
    dispatch({ type: UPDATE_ROLE_RESET })
    if (updateRoleData) {
      toast('Role updated successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        history.push('/roles')
      }, 1000)
    } else if (errorUpdateRoleInfo) {
      toast(errorUpdateRoleInfo, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [updateRoleData, errorUpdateRoleInfo])

  if (loading) {
    return <Loading />
  }

  return (
    <div className='w-full h-full'>
      <h1 className='text-2xl font-semibold'>Add Role</h1>
      <div className='bg-white shadow-md rounded px-8 py-8 my-4'>
        <h3 className='text-md font-semibold mb-2'>Name</h3>
        <Input
          name='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled
        />
        <h3 className='text-md font-semibold mt-8'>Tasks</h3>
        <div class='flex flex-wrap md:items-center mt-4'>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true ? setTasksView(true) : noTasksView()
              }
              checked={tasksView === true ? true : false}
            />
            <span class='text-sm'>View Tasks</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setTasksDetailsView(true)
                  : setTasksDetailsView(false)
              }
              checked={tasksDetailsView === true ? true : false}
            />
            <span class='text-sm'>View Tasks Details</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setTasksUpdate(true)
                  : setTasksUpdate(false)
              }
              checked={tasksUpdate === true ? true : false}
            />
            <span class='text-sm'>Update Tasks</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? tasksAddByCustomer()
                  : setTasksAddCustomer(false)
              }
              checked={tasksAddCustomer === true ? true : false}
            />
            <span class='text-sm'>Tasks Add By Customer</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? tasksAddByAllocator()
                  : setTasksAddAllocation(false)
              }
              checked={tasksAddAllocation === true ? true : false}
            />
            <span class='text-sm'>Tasks Add By Allocator</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setAllocationPendingTasks(true)
                  : setAllocationPendingTasks(false)
              }
              checked={allocationPendingTasks === true ? true : false}
            />
            <span class='text-sm'>View Allocation Pending Tasks</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setVisitPendingTasks(true)
                  : setVisitPendingTasks(false)
              }
              checked={visitPendingTasks === true ? true : false}
            />
            <span class='text-sm'>View Visit Pending Tasks</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setInProgressTasks(true)
                  : setInProgressTasks(false)
              }
              checked={inProgressTasks === true ? true : false}
            />
            <span class='text-sm'>View In Progress Tasks</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setFinalisationPendingTasks(true)
                  : setFinalisationPendingTasks(false)
              }
              checked={finalisationPendingTasks === true ? true : false}
            />
            <span class='text-sm'>View Finalisation Pending Tasks</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setCompletedTasks(true)
                  : setCompletedTasks(false)
              }
              checked={completedTasks === true ? true : false}
            />
            <span class='text-sm'>View Completed Tasks</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setWaivedTasks(true)
                  : setWaivedTasks(false)
              }
              checked={waivedTasks === true ? true : false}
            />
            <span class='text-sm'>View Waived Tasks</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true ? setCalling(true) : setCalling(false)
              }
              checked={calling === true ? true : false}
            />
            <span class='text-sm'>Calling</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setTaskSummary(true)
                  : setTaskSummary(false)
              }
              checked={taskSummary === true ? true : false}
            />
            <span class='text-sm'>Task Summary</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setTransfers(true)
                  : setTransfers(false)
              }
              checked={transfers === true ? true : false}
            />
            <span class='text-sm'>Transfers</span>
          </label>
        </div>
        <h3 className='text-md font-semibold mt-8'>Users</h3>
        <div class='flex flex-wrap md:items-center mt-4'>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setUsersView(true)
                  : setUsersView(false)
              }
              checked={usersView === true ? true : false}
            />
            <span class='text-sm'>View Users</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setUsersAdd(true)
                  : setUsersAdd(false)
              }
              checked={usersAdd === true ? true : false}
            />
            <span class='text-sm'>Add Users</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setUsersUpdate(true)
                  : setUsersUpdate(false)
              }
              checked={usersUpdate === true ? true : false}
            />
            <span class='text-sm'>Update Users</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setResignedUsers(true)
                  : setResignedUsers(false)
              }
              checked={resignedUsers === true ? true : false}
            />
            <span class='text-sm'>Resigned Users</span>
          </label>
        </div>
        <h3 className='text-md font-semibold mt-8'>Roles</h3>
        <div class='flex flex-wrap md:items-center mt-4'>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setRolesView(true)
                  : setRolesView(false)
              }
              checked={rolesView === true ? true : false}
            />
            <span class='text-sm'>View Roles</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setRolesAdd(true)
                  : setRolesAdd(false)
              }
              checked={rolesAdd === true ? true : false}
            />
            <span class='text-sm'>Add Roles</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setRolesUpdate(true)
                  : setRolesUpdate(false)
              }
              checked={rolesUpdate === true ? true : false}
            />
            <span class='text-sm'>Update Roles</span>
          </label>
        </div>
        <h3 className='text-md font-semibold mt-8'>Customers</h3>
        <div class='flex flex-wrap md:items-center mt-4'>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setCustomersView(true)
                  : setCustomersView(false)
              }
              checked={customersView === true ? true : false}
            />
            <span class='text-sm'>View Customers</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setCustomersAdd(true)
                  : setCustomersAdd(false)
              }
              checked={customersAdd === true ? true : false}
            />
            <span class='text-sm'>Add Customers</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setCustomersUpdate(true)
                  : setCustomersUpdate(false)
              }
              checked={customersUpdate === true ? true : false}
            />
            <span class='text-sm'>Update Customers</span>
          </label>
        </div>
        <h3 className='text-md font-semibold mt-8'>Login Access</h3>
        <div class='flex flex-wrap md:items-center mt-4'>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setAppAccess(true)
                  : setAppAccess(false)
              }
              checked={appAccess === true ? true : false}
            />
            <span class='text-sm'>App Access</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setWebAccess(true)
                  : setWebAccess(false)
              }
              checked={webAccess === true ? true : false}
            />
            <span class='text-sm'>Web Access</span>
          </label>
        </div>
        <h3 className='text-md font-semibold mt-8'>Settings</h3>
        <div class='flex flex-wrap md:items-center mt-4'>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setSettings(true)
                  : setSettings(false)
              }
              checked={settings === true ? true : false}
            />
            <span class='text-sm'>Settings</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true ? setReports(true) : setReports(false)
              }
              checked={reports === true ? true : false}
            />
            <span class='text-sm'>Reports</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setPincodes(true)
                  : setPincodes(false)
              }
              checked={pincodes === true ? true : false}
            />
            <span class='text-sm'>Pincodes</span>
          </label>
          <label class='w-1/3 text-gray-500 font-bold mt-6'>
            <input
              class='mr-2 leading-tight'
              type='checkbox'
              onChange={(e) =>
                e.target.checked === true
                  ? setDashboard(true)
                  : setDashboard(false)
              }
              checked={dashboard === true ? true : false}
            />
            <span class='text-sm'>Dashboard</span>
          </label>
        </div>
        <Button
          type='button'
          text='Update'
          custom='mt-6 py-2 px-6'
          onClick={updateRoleHandler}
        />
      </div>
    </div>
  )
}

export default RoleByID
