export const GET_PINCODES_REQUEST = 'GET_PINCODES_REQUEST'
export const GET_PINCODES_SUCCESS = 'GET_PINCODES_SUCCESS'
export const GET_PINCODES_FAIL = 'GET_PINCODES_FAIL'
export const GET_PINCODES_RESET = 'GET_PINCODES_RESET'

export const ADD_PINCODE_REQUEST = 'ADD_PINCODE_REQUEST'
export const ADD_PINCODE_SUCCESS = 'ADD_PINCODE_SUCCESS'
export const ADD_PINCODE_FAIL = 'ADD_PINCODE_FAIL'
export const ADD_PINCODE_RESET = 'ADD_PINCODE_RESET'

export const UPDATE_PINCODE_REQUEST = 'UPDATE_PINCODE_REQUEST'
export const UPDATE_PINCODE_SUCCESS = 'UPDATE_PINCODE_SUCCESS'
export const UPDATE_PINCODE_FAIL = 'UPDATE_PINCODE_FAIL'
export const UPDATE_PINCODE_RESET = 'UPDATE_PINCODE_RESET'
