import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'

import Loading from '../components/Loading'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Select from '../components/Select'
import {
  addPincode,
  getPincodes,
  updatePincode,
} from '../actions/pincodeActions'
import {
  GET_PINCODES_RESET,
  UPDATE_PINCODE_RESET,
} from '../constants/pincodeConstants'
import Input from '../components/Input'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const Pincodes = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [id, setId] = useState('')
  const [assignedTo, setAssignedTo] = useState('')
  const [users, setUsers] = useState([])
  const [usersOptions, setUsersOptions] = useState([])
  const [addPincodeModal, setAddPincodeModal] = useState(false)
  const [pincode, setPincode] = useState('')
  const [user, setUser] = useState('')

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.pincodes) {
        history.push('/')
      }
    }
  }, [userInfo, getRoleData, history])

  useEffect(() => {
    dispatch(getPincodes())
  }, [])

  // * Users Table
  const headCells = [
    {
      field: 'pincode',
      title: 'Pincode',
      render: (rowData) => (
        <div
          className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
          onClick={() => updatePincodeModal(rowData)}
        >
          <p>{rowData.pincode}</p>
        </div>
      ),
    },
    {
      field: 'user',
      title: 'User',
      render: (rowData) => (
        <p>
          {rowData.user.name} ({rowData.user.identifier})
        </p>
      ),
    },
  ]

  const getPincodesInfo = useSelector((state) => state.getPincodesInfo)
  const { loadingGetPincodes, errorGetPincodes, getPincodesData } =
    getPincodesInfo

  useEffect(() => {
    dispatch({ type: GET_PINCODES_RESET })
    if (getPincodesData) {
      setData(getPincodesData.pincodes)
      setUsers(getPincodesData.users)
    } else if (errorGetPincodes) {
      toast(errorGetPincodes, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getPincodesData, errorGetPincodes])

  // * Users
  useEffect(() => {
    if (users.length > 0) {
      let data = []
      users.forEach((item) => {
        data.push({
          id: item._id,
          title: `${item.identifier} - ${item.name}`,
        })
      })
      setUsersOptions(data)
    }
  }, [users])

  // * Add Pincode
  const closeAddPincodeModal = () => {
    setAddPincodeModal(false)
    setPincode('')
    setUser('')
  }

  const addPincodeHandler = () => {
    if (!pincode || !user) {
      toast('All the fields are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(addPincode(pincode, user))
    }
  }

  const addPincodeInfo = useSelector((state) => state.addPincodeInfo)
  const { loadingAddPincode, addPincodeData, errorAddPincode } = addPincodeInfo

  useEffect(() => {
    if (addPincodeData) {
      toast(addPincodeData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      closeAddPincodeModal()
      setTimeout(() => {
        dispatch(getPincodes())
      }, 1000)
    } else if (errorAddPincode) {
      toast(errorAddPincode, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addPincodeData, errorAddPincode])

  // * Update Pincode
  const updatePincodeModal = (item) => {
    setOpenModal(true)
    setId(item._id)
    setPincode(item.pincode)
  }

  const closeUpdatePincodeModal = () => {
    setOpenModal(false)
    setId('')
    setPincode('')
    setUser('')
  }

  const updatePincodeHandler = () => {
    if (!id || !user) {
      toast('All the fields are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(updatePincode(id, user))
    }
  }

  const updatePincodeInfo = useSelector((state) => state.updatePincodeInfo)
  const { loadingUpdatePincode, errorUpdatePincode, updatePincodeData } =
    updatePincodeInfo

  useEffect(() => {
    dispatch({ type: UPDATE_PINCODE_RESET })
    if (updatePincodeData) {
      toast(updatePincodeData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      closeUpdatePincodeModal()
      setTimeout(() => {
        dispatch(getPincodes())
      }, 1000)
    } else if (errorUpdatePincode) {
      toast(errorUpdatePincode, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [updatePincodeData, errorUpdatePincode])

  if (loadingGetPincodes) {
    return <Loading />
  }

  return (
    <div className='w-full h-full'>
      <h1 className='text-2xl font-semibold mb-4'>Pincodes</h1>
      <MaterialTable
        icons={tableIcons}
        title={''}
        columns={headCells}
        data={data}
        options={{
          exportButton: false,
          search: true,
          exportAllData: false,
          rowStyle: {
            height: '5px',
            fontSize: 13,
          },
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
          headerStyle: {
            position: 'sticky',
            top: '0',
          },
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add Pincode',
            isFreeAction: true,
            onClick: (event) => setAddPincodeModal(true),
          },
        ]}
      />
      {openModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Update Pincode</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <Input
                    width='w-full'
                    style={{ marginBottom: 16 }}
                    name='Pincode *'
                    type='number'
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    disabled
                  />
                  <Select
                    width='w-full mb-4'
                    name='User *'
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    options={usersOptions}
                  />
                  <p className='text-sm'>All the fields with * are mandatory</p>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closeUpdatePincodeModal}
                  >
                    Close
                  </button>

                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                    type='button'
                    onClick={updatePincodeHandler}
                    disabled={loadingUpdatePincode}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
      {addPincodeModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-lg font-semibold'>Add Pincode</h3>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto'>
                  <Input
                    width='w-full'
                    style={{ marginBottom: 16 }}
                    name='Pincode *'
                    type='number'
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                  <Select
                    width='w-full mb-4'
                    name='User *'
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    options={usersOptions}
                  />
                  <p className='text-sm mt-4'>
                    All the fields with * are mandatory
                  </p>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closeAddPincodeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                    type='button'
                    onClick={addPincodeHandler}
                    disabled={loadingAddPincode}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </div>
  )
}

export default Pincodes
