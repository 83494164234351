import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Select from '../components/Select'
import Button from '../components/Button'
import Loading from '../components/Loading'
import {
  getSetting,
  settingCallingDistance,
  updateAppVersion,
} from '../actions/settingActions'
import {
  CURRENT_APP_VERSION_RESET,
  GET_SETTING_RESET,
  SETTING_CALLING_DISTANCE_RESET,
} from '../constants/settingConstants'
import Input from '../components/Input'

const Settings = ({ history }) => {
  // * States
  const [distanceForCalling, setDistanceForCalling] = useState(0)
  const [loading, setLoading] = useState(false)
  const [currentAppVersion, setCurrentAppVersion] = useState('')

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.settings) {
        history.push('/')
      }
    }
  }, [userInfo, getRoleData, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getSetting())
  }, [])

  const settingInfo = useSelector((state) => state.settingInfo)
  const { loadingGetSetting, errorGetSetting, getSettingData } = settingInfo

  useEffect(() => {
    dispatch({ type: GET_SETTING_RESET })
    if (getSettingData) {
      setLoading(false)
      setDistanceForCalling(getSettingData.distanceForCalling)
      setCurrentAppVersion(getSettingData.currentAppVersion)
    } else if (errorGetSetting) {
      setLoading(false)
      toast(errorGetSetting, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getSettingData, errorGetSetting])

  const distanceForCallingSubmitHandler = () => {
    if (!distanceForCalling) {
      toast('Distance for calling is mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(settingCallingDistance(distanceForCalling))
    }
  }

  const settingCallingDistanceInfo = useSelector(
    (state) => state.settingCallingDistanceInfo
  )
  const {
    loadingSettingCallingDistance,
    settingCallingDistanceData,
    errorSettingCallingDistance,
  } = settingCallingDistanceInfo

  useEffect(() => {
    dispatch({ type: SETTING_CALLING_DISTANCE_RESET })
    if (settingCallingDistanceData) {
      toast(settingCallingDistanceData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getSetting())
      }, 1000)
    } else if (errorSettingCallingDistance) {
      toast(errorSettingCallingDistance, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [settingCallingDistanceData, errorSettingCallingDistance])

  const updateVersion = () => {
    if (!currentAppVersion) {
      toast('Current app version is mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(updateAppVersion(currentAppVersion))
    }
  }

  const currentAppVersionInfo = useSelector(
    (state) => state.currentAppVersionInfo
  )
  const {
    loadingCurrentAppVersion,
    currentAppVersionData,
    errorCurrentAppVersion,
  } = currentAppVersionInfo

  useEffect(() => {
    dispatch({ type: CURRENT_APP_VERSION_RESET })
    if (currentAppVersionData) {
      toast(currentAppVersionData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getSetting())
      }, 1000)
    } else if (errorCurrentAppVersion) {
      toast(errorCurrentAppVersion, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [currentAppVersionData])

  if (loading) {
    return <Loading />
  }

  return (
    <div className='w-full h-full'>
      <h1 className='text-2xl font-semibold'>Settings</h1>
      <div className='flex flex-wrap w-full gap-5'>
        <div className='bg-white shadow-md rounded p-6 my-4'>
          <h3 className='text-sm font-semibold'>Distance for Calling</h3>
          <Select
            width='w-full my-4'
            name='Distance for Calling *'
            value={distanceForCalling}
            onChange={(e) => setDistanceForCalling(e.target.value)}
            options={[
              { id: 0.1, title: '100 m' },
              { id: 0.2, title: '200 m' },
              { id: 0.3, title: '300 m' },
              { id: 0.4, title: '400 m' },
              { id: 0.5, title: '500 m' },
              { id: 0.6, title: '600 m' },
              { id: 0.7, title: '700 m' },
              { id: 0.8, title: '800 m' },
              { id: 0.9, title: '900 m' },
              { id: 1.0, title: '1 km' },
              { id: 100, title: 'No restriction' },
            ]}
          />
          <Button
            custom='py-2'
            type='button'
            onClick={distanceForCallingSubmitHandler}
            text='Update'
            loading={loadingSettingCallingDistance}
          />
        </div>
        <div className='bg-white shadow-md rounded p-6 my-4'>
          <h3 className='text-sm font-semibold'>Current App Version</h3>
          <Input
            name='Current App Version'
            width='w-full my-4'
            value={currentAppVersion}
            onChange={(e) => setCurrentAppVersion(e.target.value)}
          />
          <Button
            custom='py-2'
            type='button'
            onClick={updateVersion}
            text='Update'
            loading={loadingCurrentAppVersion}
          />
        </div>
      </div>
    </div>
  )
}

export default Settings
