import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

import { users } from '../actions/userActions'
import Loading from '../components/Loading'
import { USERS_REPORT_RESET } from '../constants/userConstants'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const UserReport = ({ history }) => {
  const dispatch = useDispatch()

  const [data, setData] = useState([])

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { loadingUserInfo, userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  // * Check for role
  const getRoleInfo = useSelector((state) => state.getRoleInfo)
  const { loadingGetRole, getRoleData } = getRoleInfo

  useEffect(() => {
    if (getRoleData) {
      if (!getRoleData.reports) {
        history.push('/')
      }
    }
  }, [userInfo, getRoleData, history])

  useEffect(() => {
    dispatch(users())
  }, [])

  const usersReportInfo = useSelector((state) => state.usersReportInfo)
  const { loadingUsersReport, errorUsersReport, usersReportData } =
    usersReportInfo

  useEffect(() => {
    dispatch({ type: USERS_REPORT_RESET })
    if (usersReportData) {
      setData(usersReportData)
    } else if (errorUsersReport) {
      toast(errorUsersReport, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [errorUsersReport, usersReportData])

  const headCells = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'email',
      title: 'Email',
    },
    {
      field: 'identifier',
      title: 'Identifier',
    },
    {
      field: 'role.name',
      title: 'Role',
    },
    {
      field: 'customers.length',
      title: 'Customers',
    },
    {
      field: 'status',
      title: 'Status',
      render: (rowData) => (rowData.status === true ? 'Active' : 'Not Active'),
    },
    {
      field: 'joinedOn',
      title: 'Joined On',
    },
    {
      field: 'resignedOn',
      title: 'Resigned On',
    },
    {
      field: 'deviceInfo.brand',
      title: 'Device Brand',
    },
    {
      field: 'deviceInfo.model',
      title: 'Device Model',
    },
  ]

  if (loadingUsersReport) {
    return <Loading />
  }

  return (
    <div className='w-full h-full'>
      <h1 className='text-2xl font-semibold mb-5'>Users Report</h1>
      <MaterialTable
        icons={tableIcons}
        title={''}
        columns={headCells}
        data={data}
        options={{
          exportButton: true,
          search: true,
          exportAllData: true,
          rowStyle: {
            height: '5px',
            fontSize: 13,
          },
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
          headerStyle: {
            position: 'sticky',
            top: '0',
          },
        }}
      />
    </div>
  )
}

export default UserReport
