import {
  ADD_PINCODE_FAIL,
  ADD_PINCODE_REQUEST,
  ADD_PINCODE_RESET,
  ADD_PINCODE_SUCCESS,
  GET_PINCODES_FAIL,
  GET_PINCODES_REQUEST,
  GET_PINCODES_RESET,
  GET_PINCODES_SUCCESS,
  UPDATE_PINCODE_FAIL,
  UPDATE_PINCODE_REQUEST,
  UPDATE_PINCODE_RESET,
  UPDATE_PINCODE_SUCCESS,
} from '../constants/pincodeConstants'

export const getPincodesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PINCODES_REQUEST:
      return { loadingGetPincodes: true }
    case GET_PINCODES_SUCCESS:
      return { loadingGetPincodes: false, getPincodesData: action.payload }
    case GET_PINCODES_FAIL:
      return { loadingGetPincodes: false, errorGetPincodes: action.payload }
    case GET_PINCODES_RESET:
      return {}
    default:
      return state
  }
}

export const addPincodeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_PINCODE_REQUEST:
      return { loadingAddPincode: true }
    case ADD_PINCODE_SUCCESS:
      return { loadingAddPincode: false, addPincodeData: action.payload }
    case ADD_PINCODE_FAIL:
      return { loadingAddPincode: false, errorAddPincode: action.payload }
    case ADD_PINCODE_RESET:
      return {}
    default:
      return state
  }
}

export const updatePincodeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PINCODE_REQUEST:
      return { loadingUpdatePincode: true }
    case UPDATE_PINCODE_SUCCESS:
      return {
        loadingUpdatePincode: false,
        updatePincodeData: action.payload,
      }
    case UPDATE_PINCODE_FAIL:
      return {
        loadingUpdatePincode: false,
        errorUpdatePincode: action.payload,
      }
    case UPDATE_PINCODE_RESET:
      return {}
    default:
      return state
  }
}
