import React from 'react'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Login from './screens/Login'
import SideBar from './components/SideBar'
import Dashboard from './screens/Dashboard'
import Container from './components/Container'
import Customers from './screens/Customers'
import Roles from './screens/Roles'
import AddRole from './screens/AddRole'
import RoleByID from './screens/RoleByID'
import TaskList from './screens/TaskList'
import TaskByID from './screens/TaskByID'
import UserList from './screens/UserList'
import UserByID from './screens/UserByID'
import Transfers from './screens/Transfers'
import ResignedUsers from './screens/ResignedUsers'
import Reports from './screens/Reports'
import AttendanceReport from './screens/AttendanceReport'
import TaskReport from './screens/TaskReport'
import UserReport from './screens/UserReport'
import Settings from './screens/Settings'
import Pincodes from './screens/Pincodes'

function App() {
  return (
    <Router>
      <ToastContainer />
      <Switch>
        <Route path='/' component={Login} exact />
        <Route component={DefaultContainer} />
      </Switch>
    </Router>
  )
}

const DefaultContainer = () => {
  return (
    // <>
    <Container>
      <SideBar />
      <div className='mx-auto py-10 px-6 bg-gray-100 overflow-x-auto w-4/5'>
        <Route path='/dashboard' component={Dashboard} exact />
        <Route path='/customers' component={Customers} exact />
        <Route path='/roles' component={Roles} exact />
        <Route path='/roles/add' component={AddRole} exact />
        <Route path='/roles/update/:id' component={RoleByID} />
        <Route path='/tasks' component={TaskList} exact />
        <Route path='/tasks/:id' component={TaskByID} />
        <Route path='/users' component={UserList} exact />
        <Route path='/resigned' component={ResignedUsers} exact />
        <Route path='/users/:id' component={UserByID} />
        <Route path='/transfers' component={Transfers} exact />
        <Route path='/reports' component={Reports} exact />
        <Route path='/reports/attendance' component={AttendanceReport} exact />
        <Route path='/reports/task' component={TaskReport} exact />
        <Route path='/reports/users' component={UserReport} exact />
        <Route path='/settings' component={Settings} exact />
        <Route path='/pincodes' component={Pincodes} exact />
      </div>
    </Container>
    // {/* <Redirect from='*' to='/dashboard' /> */}
    // {/* </> */}
  )
}

export default App
